import React, { useRef } from "react";
import { Flex, Box, Button, FlexProps, Heading } from "rebass";
import { Label, Input } from "@rebass/forms";
import { useCallback, useState } from "react";
import { useApiResponse } from "../hooks/useApiResponse";
import authStore from "../stores/AuthStore";
import api from "../util/endpoints";
import { requestHidePage } from "../util/gameInterface";
import { FormattedMessage } from "react-intl";
import { ErrorBox } from "./ErrorBox";
import { Link } from "react-router-dom";
import config from "../util/config";
import { validateEmail, validatePassword, validateUsername } from "../util/misc";

const FlexForm: React.ComponentType<FlexProps> = (Flex as any).withComponent('form')

export default function LoginForm() {
  const [isSignup, setSignup] = useState<boolean>(config.environment !== "admin")
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [supervisorFriendCode, setSupervisorFriendCode] = useState<string>("");
  const [attempt, setAttempt] = useState<{ signup: boolean } | null>(null);

  const [usernameFocus, setUsernameFocus] = useState<boolean>(true);
  const [passwordFocus, setPasswordFocus] = useState<boolean>(true);
  const [confirmPasswordFocus, setConfirmPasswordFocus] = useState<boolean>(true);
  const onUsernameFocus = useCallback(() => { setUsernameFocus(true) }, []);
  const onUsernameBlur = useCallback(() => { setUsernameFocus(false) }, []);
  const onPasswordFocus = useCallback(() => { setPasswordFocus(true) }, []);
  const onPasswordBlur = useCallback(() => { setPasswordFocus(false) }, []);
  const onConfirmPasswordFocus = useCallback(() => { setConfirmPasswordFocus(true) }, []);
  const onConfirmPasswordBlur = useCallback(() => { setConfirmPasswordFocus(false) }, []);

  const loginState = useApiResponse(
    () =>
      attempt
        ? {
            invocation: api.loginLocal(username, password, attempt.signup, supervisorFriendCode || undefined),
            onSuccess: (data) => {
              authStore.setAuthData(data.authToken, data.userId);
            },
          }
        : null,
    [attempt], // Intentionately not including username and password to deps
  );

  const onChangeUsername = useCallback(evt => setUsername(evt.target.value), []);
  const onChangePassword = useCallback(evt => setPassword(evt.target.value), []);
  const onChangeConfirmPassword = useCallback(evt => setConfirmPassword(evt.target.value), []);
  const onChangeSupervisorFriendCode = useCallback(evt => setSupervisorFriendCode(evt.target.value || ""), []);

  const onSubmit = useCallback((evt) => {
    evt.preventDefault();
    setAttempt(() => ({ signup: isSignup }));
  }, [isSignup]);

  const onCancel = useCallback((evt) => {
    evt.preventDefault();
    requestHidePage();
  }, []);

  const inputRef = useRef(null);
  const onToggleSignup = useCallback((evt) => {
    evt.preventDefault();
    setSignup((x) => !x);
    setUsernameFocus(true);
    setPasswordFocus(true);
    const inputEl: any = inputRef.current;
    inputEl?.focus();
  }, []);

  const usernameIsValid = isSignup ? validateUsername(username) : validateUsername(username) || validateEmail(username);
  const passwordIsValid = validatePassword(password);
  const confirmPasswordIsValid = !isSignup || password === confirmPassword;
  const formIsValid = usernameIsValid && passwordIsValid && confirmPasswordIsValid;
  const usernameError = !(usernameIsValid || usernameFocus);
  const passwordError = !(passwordIsValid || passwordFocus);
  const confirmPasswordError = !(confirmPasswordIsValid || confirmPasswordFocus);

  return (
    <Flex flexDirection="column" alignItems="center">
      <FlexForm
        flexDirection="column"
        alignItems="stretch"
        maxWidth={520}
        sx={{
          width: "100%",
        }}
        px={2}
        py={3}
        onSubmit={onSubmit}
      >
        <Heading textAlign="center" color="primary">
          {isSignup ? (
            <FormattedMessage id="login.cta.header" defaultMessage="Create an account on the REThink Platform!" />
          ) : (
            <FormattedMessage id="login.cta.header.login" defaultMessage="Log into your REThink Platform account!" />
          )}
        </Heading>
        <Box
          mt={2}
          mb={3}
          sx={{
            textAlign: "center",
          }}
        >
          <FormattedMessage
            id="login.cta.description"
            defaultMessage="With an account you can unlock the full version of the REThink game, save your scores and share them with your friends and therapist."
          />
        </Box>
        <Label htmlFor="username" sx={{ color: usernameError ? "error" : undefined }}>
          {isSignup ? (
            <FormattedMessage id="login.username.label" defaultMessage="Username:" />
          ) : (
            <FormattedMessage id="login.username.label.login" defaultMessage="Username or e-mail:" />
          )}
        </Label>
        <Input
          id="username"
          type="text"
          autoComplete="username"
          value={username}
          onChange={onChangeUsername}
          pattern={isSignup ? "[a-zA-Z0-9._-]+" : undefined}
          minLength={isSignup ? 5 : undefined}
          maxLength={isSignup ? 24 : undefined}
          onFocus={onUsernameFocus}
          onBlur={onUsernameBlur}
          sx={{ borderColor: usernameError ? "error" : undefined }}
          ref={inputRef}
        />
        {usernameError && username !== "" && (
          <Box
            sx={{
              color: "error",
              fontSize: 1,
            }}
            mb={1}
          >
            <FormattedMessage
              id="login.username.error"
              defaultMessage="Username must be between 5 and 24 characters long. Supported characters: a-z A-Z 0-9 . _ -"
            />
          </Box>
        )}

        <Label htmlFor="password" sx={{ marginTop: 1, color: passwordError ? "error" : undefined }} mt={2}>
          <FormattedMessage id="login.password.label" defaultMessage="Password:" />
        </Label>
        <Input
          id="password"
          type="password"
          autoComplete={isSignup ? "new-password" : "current-password"}
          value={password}
          onChange={onChangePassword}
          minLength={6}
          onFocus={onPasswordFocus}
          onBlur={onPasswordBlur}
          sx={{ borderColor: passwordError ? "error" : undefined }}
        />
        {passwordError && password !== "" && (
          <Box
            sx={{
              color: "error",
              fontSize: 1,
            }}
            mb={1}
          >
            <FormattedMessage id="login.password.error" defaultMessage="Password must be at least 6 characters long" />
          </Box>
        )}

        {isSignup && (
          <>
            <Label
              htmlFor="confirm-password"
              sx={{ marginTop: 1, color: confirmPasswordError ? "error" : undefined }}
              mt={2}
            >
              <FormattedMessage id="login.confirm-password.label" defaultMessage="Confirm password:" />
            </Label>
            <Input
              id="confirm-password"
              type="password"
              autoComplete="new-password"
              value={confirmPassword}
              onChange={onChangeConfirmPassword}
              onFocus={onConfirmPasswordFocus}
              onBlur={onConfirmPasswordBlur}
              sx={{ borderColor: confirmPasswordError ? "error" : undefined }}
            />
          </>
        )}
        {isSignup && confirmPasswordError && confirmPassword !== "" && (
          <Box
            sx={{
              color: "error",
              fontSize: 1,
            }}
            mb={1}
          >
            <FormattedMessage id="login.confirm-password.error" defaultMessage="Passwords don't match" />
          </Box>
        )}

        {isSignup && (
          <>
            <Label htmlFor="supervisorFriendCode" mt={2}>
              <FormattedMessage
                id="login.supervisor-friend-code.label"
                defaultMessage="Part of a research group? Enter Researcher Code:"
              />
            </Label>
            <Input
              id="supervisorFriendCode"
              type="text"
              value={supervisorFriendCode || ""}
              onChange={onChangeSupervisorFriendCode}
            />
          </>
        )}

        <Button mt={3} mb={2} variant="primary" type="submit" disabled={!formIsValid || loginState.loading}>
          {isSignup ? (
            <FormattedMessage id="login.button.signup" defaultMessage="Sign Up" />
          ) : (
            <FormattedMessage id="login.button.login" defaultMessage="Login" />
          )}
        </Button>

        <Button mb={2} variant="outline" disabled={loginState.loading} onClick={onCancel}>
          <FormattedMessage id="login.button.skip" defaultMessage="Set up later" />
        </Button>

        <Box
          mt={3}
          sx={{
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          {isSignup ? (
            <FormattedMessage id="login.switch.login" defaultMessage="Already have an account?" />
          ) : (
            <FormattedMessage id="login.switch.signup" defaultMessage="Don't have an account?" />
          )}
        </Box>

        <Button mt={1} mb={2} variant="outline" onClick={onToggleSignup}>
          {isSignup ? (
            <FormattedMessage id="login.button.login" defaultMessage="Login" />
          ) : (
            <FormattedMessage id="login.button.signup" defaultMessage="Sign Up" />
          )}
        </Button>

        <Box
          mb={2}
          py={1}
          sx={{
            textAlign: "center",
            a: {
              color: "primary",
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
              },
            },
          }}
        >
          <Link to="/forgot-password">
            <FormattedMessage id="login.forgot-password" defaultMessage="Forgot password?" />
          </Link>
          {" | "}
          <Link to="/privacy">
            <FormattedMessage id="login.privacy-policy" defaultMessage="Privacy policy" />
          </Link>
          {" | "}
          <Link to="/terms">
            <FormattedMessage id="login.terms" defaultMessage="Terms and conditions" />
          </Link>
          {" | "}
          <Link to="/disclaimer">
            <FormattedMessage id="login.disclaimer" defaultMessage="Disclaimer" />
          </Link>
          {" | "}
          <Link to="/licenses">
            <FormattedMessage id="login.software-licenses" defaultMessage="Software licenses" />
          </Link>
          {/* " | " */}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          {/* <a href="#" onClick={onShowSupervisorField}>
            <FormattedMessage id="login.show-supervisor" defaultMessage="Part of a research group?" />
          </a> */}
        </Box>
        <ErrorBox>{loginState.error}</ErrorBox>
      </FlexForm>
    </Flex>
  );
}

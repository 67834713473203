import React, { ReactNode, useCallback } from "react";
import { IntlProvider } from "react-intl";
import enMessages from "../intl/en.json";
import roMessages from "../intl/ro.json";
import { useLanguageStore } from "../stores/LanguageStore";

const allMessages: Record<string, any> = {
  "en": enMessages,
  "ro": roMessages,
};

export function IntlWrapper({ children }: { children: ReactNode }) {
  const { languages } = useLanguageStore();

  let messages = enMessages;
  const language =
    languages.find((lang) => {
      if (allMessages[lang]) {
        messages = allMessages[lang];
        return true;
      }
      const shortLang = lang.replace(/-.*$/, "");
      if (allMessages[shortLang]) {
        messages = allMessages[shortLang];
        return true;
      }
      return false;
    }) ?? "en-US";

  const errorHandler = useCallback(err => {
    // console.warn(err);
  }, []);

  return (
    <IntlProvider messages={messages} locale={language} defaultLocale="en-US" onError={errorHandler} >
      {children}
    </IntlProvider>
  );
}

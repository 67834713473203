import config from "../util/config";
import gameInterface from "../util/gameInterface";
import { loadValue, saveValue } from "../util/storage";
import { Store, useStore } from "./Store"

export type LanguageStoreData = {
  languages: readonly string[],
  source: "game" | "internal",
}

export type LanguageStoreSavedData = {
  languages: readonly string[],
}

type SetLanguagePayload = readonly string[];

export class LanguageStore extends Store<LanguageStoreData> {
  data: LanguageStoreData = {
    languages: ["en-US"],
    source: "internal",
  };

  constructor() {
    super();

    this.subscribe((data) => {
      const { languages, source } = data;
      saveValue<LanguageStoreSavedData>("languages", { languages });
      if (source === "game") return;
      gameInterface.pushGameMessage<"set_language", SetLanguagePayload>("set_language", languages);
    });

    if (config.environment === "web" || config.environment === "admin") {
      const languages = loadValue<LanguageStoreSavedData>("languages")?.languages ??
        navigator.languages ?? [navigator.language || "en-US"];

      this.setData({ languages, source: "internal" });
    }

    gameInterface.subscribeToWebMessage<SetLanguagePayload>("set_language", (languages) => {
      this.setData({ languages, source: "game" });
    });
  }
}

const languageStore = new LanguageStore();
export default languageStore;

export const useLanguageStore = (): LanguageStoreData => {
  return useStore(languageStore);
}

import config, { AppConfig } from "./config";

type GameMessage<Type extends string, Payload> = { type: Type, payload: Payload };

interface GameInterface {
  popGameMessage: () => string;
  pushWebMessage: <Type extends string, Payload>(type: Type, payload: Payload) => void;
}

interface GameInterfaceInternal extends GameInterface {
  config: AppConfig;
  pushGameMessage: <Type extends string, Payload>(type: Type, payload: Payload) => void;
  subscribeToWebMessage: <Payload>(type: string, handler: (payload: Payload) => void) => () => void;
};

const gameMessageQueue: GameMessage<any, any>[] = [];
const webMessageStates: Map<string, {
  queue: any[];
  handlers: Set<(payload: any) => void>;
}> = new Map();

const getMessageState = (type: string) => {
  let state = webMessageStates.get(type);
  if (!state) {
    state = { queue: [], handlers: new Set() };
    webMessageStates.set(type, state);
  }
  return state;
}

const gameInterface: GameInterfaceInternal = {
  config,

  pushGameMessage: (type, payload) => {
    if (process.env.NODE_ENV === "development") {
      console.log("pushGameMessage", type, payload);
    }
    gameMessageQueue.push({ type, payload });
  },

  popGameMessage: () => {
    if (gameMessageQueue.length === 0) return "null";
    return JSON.stringify(gameMessageQueue.splice(0, 1)[0] || null);
  },

  pushWebMessage: (type, payload) => {
    if (process.env.NODE_ENV === "development") {
      console.log("pushWebMessage", type, payload);
    }
    const { queue, handlers } = getMessageState(type);
    if (handlers.size === 0) {
      queue.push(payload);
    } else {
      handlers.forEach(handler => {
        handler(payload);
      })
    }
  },

  subscribeToWebMessage: (type, handler) => {
    const state = getMessageState(type);
    state.handlers.add(handler);

    if (state.queue.length) {
      const queue = state.queue;
      state.queue = [];
      queue.forEach(payload => {
        handler(payload);
      });
    }

    return () => { state.handlers.delete(handler); }
  },
};

export function requestHidePage() {
  gameInterface.pushGameMessage("request_hide_page", null);
}

if (process.env.REACT_APP_INITIAL_PAGE) {
  gameInterface.pushWebMessage("show_page", process.env.REACT_APP_INITIAL_PAGE)
}

(window as any).__GameInterface = gameInterface;

export default gameInterface;

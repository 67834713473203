import preset from "@rebass/preset";

const theme = {
  ...preset,
  colors: {
    ...preset.colors,
    error: "#aa0000",
    textLight: "#fff",
    primary: "#331a80",
    secondary: "#4860db",
    lightGray: "#aaa",
    fadedGray: "#ccc",
    confirm: "#43a047",
    reject: "#e53935",
  },
  shadows: {
    ...preset.shadows,
    modal: "0px 4px 15px 0px rgba(0,0,0,0.5)",
  },
  buttons: {
    ...preset.buttons,
    primary: {
      ...preset.buttons.primary,
      cursor: "pointer",
      "&:disabled": {
        cursor: "initial",
        backgroundColor: "muted",
      },
    },
    outline: {
      ...preset.buttons.outline,
      "&:disabled": {
        color: "muted",
        backgroundColor: "transparent",
      },
    },
  },
};

export default theme;

import React, { ReactNode, useCallback, useState } from "react";
import { useAuthStore } from "../stores/AuthStore";
import FirstLoginPage from "./FirstLoginPage";
import LoginForm from "./LoginForm";
import { PageLoader } from "./PageLoader";
import ProfileForm from "./ProfileForm";

type Props = {
  children: ReactNode;
  loader?: ReactNode;
  loginForm?: ReactNode;
};

export function RequireAuth(props: Props) {
  const auth = useAuthStore();
  const { data, loading } = auth.userRequest;

  const [firstLogin, setFirstLogin] = useState<boolean>(false);
  const onProfileChange = useCallback(() => {
    setFirstLogin(true);
  }, []);
  const onFirstLoginDismiss = useCallback(() => {
    setFirstLogin(false);
  }, []);

  if (data && (!data.firstName || !data.lastName || !data.birthDay || data.gender == null)) {
    return <ProfileForm key={data.id} showSignOut onChange={onProfileChange} />
  }
  if (data) {
    if (firstLogin) {
      return <FirstLoginPage name={data.firstName || ""} onDismiss={onFirstLoginDismiss} />
    }
    return <>{props.children}</>
  }
  if (loading) {
    return <>{props.loader ?? <PageLoader/>}</>
  }
  return <LoginForm />
}

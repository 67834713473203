import { faArrowLeft, faCogs, faMedal, faTimes, faUserCog, faUserFriends, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode } from 'react';
import { Link, Route, useHistory } from 'react-router-dom';
import { Box, Flex } from 'rebass';
import { useAuthStore } from '../stores/AuthStore';
import { UserRole } from '../types/User';
import config from '../util/config';
import { requestHidePage } from '../util/gameInterface';

const FlexLink: React.ComponentType<any> = (Flex as any).withComponent(Link);

export function NavButton({ icon, path }: { icon: IconDefinition; path: string }) {
  return (
    <Route path={path}>
      {({ match }) => (
        <FlexLink
          sx={{
            color: "textLight",
            textDecoration: "none",
            position: "relative",
            alignItems: "center",
            justifyContent: "center",
            width: 50,
          }}
          to={path}
        >
          {match && (
            <Box
              sx={{
                position: "absolute",
                top: 1,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "secondary",
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
              }}
            />
          )}
          <FontAwesomeIcon icon={icon} size="lg" style={{ zIndex: 1 }} />
        </FlexLink>
      )}
    </Route>
  );
}

export function AppBar({ children }: { children: ReactNode }) {
  return (
    <Flex
      sx={{
        position: config.environment === "web" ? "absolute" : "fixed",
        flexDirection: "row",
        alignItems: "stretch",
        top: 0,
        left: 0,
        backgroundColor: "primary",
        width: "100%",
        height: 50,
        color: "textLight",
        userSelect: "none",
      }}
    >
      {children}
    </Flex>
  );
}

export function NavBar() {
  const auth = useAuthStore();
  const user = auth.userRequest.data;
  const roles = user?.roles || [];

  return (
    <AppBar>
      {(config.environment === "web" || config.environment === "game") && (
        <Flex alignItems="center" justifyContent="center" width={50} onClick={requestHidePage}>
          <FontAwesomeIcon icon={faTimes} size="2x" />
        </Flex>
      )}
      <Box
        flex={1}
        alignSelf="center"
        sx={{
          fontWeight: "bold",
          fontSize: "1.2em",
          ml: 2,
        }}
      >
        REThink
      </Box>
      {(roles.includes(UserRole.SUPERVISOR) || roles.includes(UserRole.ADMIN)) && (
        <NavButton path="/users" icon={faUserCog} />
      )}
      {roles.includes(UserRole.PLAYER) && <NavButton path="/scores" icon={faMedal} />}
      <NavButton path="/friends" icon={faUserFriends} />
      <NavButton path="/settings" icon={faCogs} />
      <Box width={8} />
    </AppBar>
  );
}

export function NavBarWrapper({ children }: { children: ReactNode; }) {
  return (
    <>
      <Box height={50} width="100%" />
      {children}
      <NavBar />
    </>
  );
}

export function TitleBar({ children }: { children: ReactNode; }) {
  const history = useHistory();
  return (
    <AppBar>
      {history.length > 1 && (
        <Flex
          alignItems="center"
          justifyContent="center"
          width={50}
          onClick={() => {
            history.goBack();
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} size="2x" />
        </Flex>
      )}
      {(config.environment === "web" || config.environment === "game") && history.length === 1 && (
        <Flex alignItems="center" justifyContent="center" width={50} onClick={requestHidePage}>
          <FontAwesomeIcon icon={faTimes} size="2x" />
        </Flex>
      )}
      <Box
        flex={1}
        sx={{
          textAlign: "center",
          color: "textLight",
          fontWeight: "heading",
          fontSize: 3,
          alignSelf: "center",
        }}
      >
        {children}
      </Box>
      {(config.environment === "web" || config.environment === "game" || history.length >= 1) && (
        <Box width={50} />
      )}
    </AppBar>
  );
}

export function TitleBarWrapper({ children, title }: { children: ReactNode; title?: ReactNode }) {
  return (
    <>
      <Box height={50} width="100%" />
      {children}
      <TitleBar>
        {title}
      </TitleBar>
    </>
  );
}

export function TextPage({ children, title }: { children: ReactNode; title?: ReactNode }) {
  return (
    <TitleBarWrapper title={title}>
      <Box px={2}>{children}</Box>
    </TitleBarWrapper>
  );
}

import React from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Flex } from "rebass";

export const PageLoader = () => (
  <Flex width={1} alignItems="center" flexDirection="column" pt={5} color="primary">
    <FontAwesomeIcon icon={faSpinner} size="3x" spin />
  </Flex>
);

export function loadValue<ValueType>(key: string): ValueType | undefined {
  try {
    const rawValue = localStorage.getItem(key);
    if (!rawValue) return undefined;
    return JSON.parse(rawValue);
  } catch (ex) {
    return undefined;
  }
}

export function saveValue<ValueType>(key: string, value: ValueType | undefined) {
  try {
    if (value === undefined) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(value));
    }
  } catch (ex) {}
}

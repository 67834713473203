import { useEffect, useState } from "react";

export class Store<DataType> {
  data!: DataType;

  subscribers: Set<(data: DataType) => void> = new Set();

  subscribe = (handler: (data: DataType) => void): () => void => {
    this.subscribers.add(handler);
    return () => {
      this.subscribers.delete(handler);
    };
  }

  emit = () => {
    this.subscribers.forEach(handler => {
      handler(this.data)
    });
  }

  setData = (data: DataType) => {
    if (this.data !== data) {
      this.data = data;
      this.emit();
    }
  }
}

export function useStore<DataType>(store: Store<DataType>): DataType {
  const [state, setState] = useState<DataType>(store.data);
  useEffect(() => {
    // In case data changes between first render and when our effect is invoked
    if (store.data !== state) {
      setState(store.data);
    }
    return store.subscribe(setState);
  }, [store]); // eslint-disable-line react-hooks/exhaustive-deps
  return state;
}

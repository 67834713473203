import React, { useCallback, useState } from "react";
import { SidebarTabProps } from "./SidebarUtils";
import { Button, Flex } from "rebass";
import { useApiResponse } from "../../hooks/useApiResponse";
import { FormattedMessage } from "react-intl";
import { ErrorBox } from "../ErrorBox";
import { PageLoader } from "../PageLoader";
import { Section, SectionTitle } from "../Section";
import { Label } from "@rebass/forms";
import { LevelOverride, LevelOverrides } from "../../types/User";
import { SegmentButton } from "../SegmentButton";
import { levelIndexToName, LevelName } from "../../types/Score";
import { LevelTitle } from "../LevelTitle";

const defaultOverride: LevelOverride = {
  unlocked: null,
  recommended: false,
  quiz: false,
  challenge: false,
}

function OverridesSidebarTabInner({ authApi, authUser, selectedUsers }: SidebarTabProps) {
  const [patch, setPatch] = useState<LevelOverrides>({});
  const [attempt, setAttempt] = useState<object | null>(null);

  const requestState = useApiResponse(() => (selectedUsers.length !== 1 ? null : authApi.getUser(selectedUsers[0])), [
    authApi,
    selectedUsers[0],
    selectedUsers.length,
  ]);

  const multiple = selectedUsers.length !== 1;

  const submitRequestState = useApiResponse(
    () =>
      attempt
        ? {
            invocation: authApi.patchOverrides(selectedUsers, patch),
            onSuccess: () => {
              if (multiple) setPatch({});
            },
          }
        : null,
    [attempt, authApi], // Intentionately not including patch to deps
  );

  const onSubmit = useCallback((evt) => {
    evt.preventDefault();
    setAttempt(() => ({}));
  }, []);

  const onChangeProperty = (level: LevelName, key: keyof LevelOverride) => (value: boolean | null | undefined) => {
    setPatch(patch => {
      const override: Partial<LevelOverride> = { ...patch[level] };
      if (value === undefined) {
        delete override[key];
      } else {
        override[key] = (value as any) as boolean;
      }
      return { ...patch, [level]: override };
    });
  };

  if (requestState.loading) {
    return <PageLoader />;
  }

  if (requestState.error) {
    return <ErrorBox>{requestState.error}</ErrorBox>
  }

  const getKey = (overrides: LevelOverrides | undefined, level: LevelName, key: keyof LevelOverride) => {
    const override: LevelOverride | undefined = overrides && overrides[level];
    return override && override[key];
  }

  const getProperty = (level: LevelName, key: keyof LevelOverride) => {
    let result = getKey(patch, level, key)
    if (multiple) return result;
    if (result === undefined) result = getKey(requestState.data?.levelOverrides, level, key);
    if (result === undefined) result = defaultOverride[key];
    return result;
  }

  return (
    <>
      <Section mx={0}>
        <SectionTitle expandedBottom>
          <FormattedMessage id="admin.overrides.unlocked.title" defaultMessage="Level access" />
        </SectionTitle>
        {levelIndexToName.map((levelName, levelIndex) => (
          <Flex px={2} key={levelName} flexDirection="row" alignItems="center">
            <Label>
              <LevelTitle index={levelIndex} />
            </Label>
            <SegmentButton
              my={1}
              sx={{ flexShrink: 0 }}
              value={getProperty(levelName, "unlocked")}
              onChange={onChangeProperty(levelName, "unlocked")}
              labelTrue={<FormattedMessage id="segment.unlocked" defaultMessage="Unlocked" />}
              labelFalse={<FormattedMessage id="segment.locked" defaultMessage="Locked" />}
              labelNull={<FormattedMessage id="segment.default" defaultMessage="Default" />}
              labelUndefined={multiple && <FormattedMessage id="segment.keep" defaultMessage="No change" />}
            />
          </Flex>
        ))}
      </Section>

      <Section mx={0}>
        <SectionTitle expandedBottom>
          <FormattedMessage id="admin.overrides.recommended.title" defaultMessage="Level recommendations" />
        </SectionTitle>
        {levelIndexToName.map((levelName, levelIndex) => (
          <Flex px={2} key={levelName} flexDirection="row" alignItems="center">
            <Label>
              <LevelTitle index={levelIndex} />
            </Label>
            <SegmentButton
              my={1}
              sx={{ flexShrink: 0 }}
              value={getProperty(levelName, "recommended")}
              onChange={onChangeProperty(levelName, "recommended")}
              labelTrue={<FormattedMessage id="segment.recommended" defaultMessage="Recommended" />}
              labelFalse={<FormattedMessage id="segment.default" defaultMessage="Default" />}
              labelUndefined={multiple && <FormattedMessage id="segment.keep" defaultMessage="No change" />}
            />
          </Flex>
        ))}
      </Section>

      <Section mx={0}>
        <SectionTitle expandedBottom>
          <FormattedMessage id="admin.overrides.quiz.title" defaultMessage="Quiz" />
        </SectionTitle>
        {levelIndexToName.map((levelName, levelIndex) => (
          <Flex px={2} key={levelName} flexDirection="row" alignItems="center">
            <Label>
              <LevelTitle index={levelIndex} />
            </Label>
            <SegmentButton
              my={1}
              sx={{ flexShrink: 0 }}
              value={getProperty(levelName, "quiz")}
              onChange={onChangeProperty(levelName, "quiz")}
              labelTrue={<FormattedMessage id="segment.enabled" defaultMessage="Enabled" />}
              labelFalse={<FormattedMessage id="segment.disabled" defaultMessage="Disabled" />}
              labelUndefined={multiple && <FormattedMessage id="segment.keep" defaultMessage="No change" />}
            />
          </Flex>
        ))}
      </Section>

      <Section mx={0}>
        <SectionTitle expandedBottom>
          <FormattedMessage id="admin.overrides.challenge.title" defaultMessage="Challenge" />
        </SectionTitle>
        {levelIndexToName.map((levelName, levelIndex) => (
          <Flex px={2} key={levelName} flexDirection="row" alignItems="center">
            <Label>
              <LevelTitle index={levelIndex} />
            </Label>
            <SegmentButton
              my={1}
              sx={{ flexShrink: 0 }}
              value={getProperty(levelName, "challenge")}
              onChange={onChangeProperty(levelName, "challenge")}
              labelTrue={<FormattedMessage id="segment.enabled" defaultMessage="Enabled" />}
              labelFalse={<FormattedMessage id="segment.disabled" defaultMessage="Disabled" />}
              labelUndefined={multiple && <FormattedMessage id="segment.keep" defaultMessage="No change" />}
            />
          </Flex>
        ))}
      </Section>

      <Button onClick={onSubmit} disabled={submitRequestState.loading}>
        <FormattedMessage id="button.save" defaultMessage="Save" />
      </Button>
      <ErrorBox>{submitRequestState.error}</ErrorBox>
    </>
  );
}

export function OverridesSidebarTab(props: SidebarTabProps) {
  return (
    <Flex flexDirection="column" m={2} alignItems="stretch">
      <OverridesSidebarTabInner key={props.selectedUsers.join(",")} {...props} />
    </Flex>
  );
}

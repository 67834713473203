import { useCallback, useEffect, useRef, useState } from "react";
import { APIInvocation, APIRequestState } from "../util/api";

export function useApiInvocationRunner(): <T>(invocation: APIInvocation<T>) => Promise<T> {
  const abortControllers = useRef<Set<AbortController> | null>(null);

  useEffect(() => {
    const set = abortControllers.current
    if (set) {
      abortControllers.current = null;
      set.forEach((controller) => {
        controller.abort();
      });
    }
  }, []);

  return useCallback((invocation) => {
    let set = abortControllers.current;
    if (!set) {
      set = new Set();
      abortControllers.current = set;
    }
    const abortController = new AbortController();
    set.add(abortController);
    return invocation(abortController.signal);
  }, []);
}

export type UseAPIResponseOptions<ResponseType> = {
  invocation?: APIInvocation<ResponseType> | null;
  discardPreviousData?: boolean | any[]; // Defaults to false. Passing an array will discard when array items change
  discardDataOnError?: boolean; // Defaults to false
  abortPreviousRequest?: boolean; // Defaults to true
  onSuccess?: (data: ResponseType) => void;
  onError?: (err: Error) => void;
  onFinish?: (err: Error | null, data: ResponseType | null) => void;
};

const nop = () => {};

export function useApiResponse<ResponseType>(
  makeInvocation: () => APIInvocation<ResponseType> | UseAPIResponseOptions<ResponseType> | undefined | void | null,
  dependencies: unknown[],
): APIRequestState<ResponseType> {
  const [state, setState] = useState<APIRequestState<ResponseType>>({
    data: null,
    hasData: false,
    loading: false,
    error: null,
  });

  const discardCache = useRef<any[] | null>(null)

  useEffect(() => {
    const inv = makeInvocation();
    const options: UseAPIResponseOptions<ResponseType> = (typeof inv === "function" ? { invocation: inv } : inv) || {};
    const {
      invocation,
      discardDataOnError = false,
      abortPreviousRequest = true,
      onSuccess = nop,
      onError = nop,
      onFinish = nop,
    } = options;

    let {
      discardPreviousData = false,
    } = options;

    if (discardPreviousData instanceof Array) {
      const prevCache = discardCache.current;
      discardCache.current = discardPreviousData
      discardPreviousData = !(
        prevCache instanceof Array &&
        prevCache.length === discardPreviousData.length &&
        discardPreviousData.findIndex((x, idx) => x !== prevCache[idx]) === -1
      );
    } else {
      discardCache.current = null;
    }

    if (!invocation && !discardPreviousData) return;

    setState((prevState) => ({
      data: discardPreviousData ? null : prevState.data,
      hasData: discardPreviousData ? false : prevState.hasData,
      loading: invocation != null,
      error: invocation != null || discardPreviousData ? null : prevState.error,
    }));

    if (!invocation) return;

    const abortController = new AbortController();

    let mounted = true;
    invocation(abortController.signal).then(
      (data) => {
        if (!mounted) return;
        setState({
          data,
          hasData: true,
          loading: false,
          error: null,
        });
        onSuccess(data);
        onFinish(null, data);
      },
      (error) => {
        if (!mounted) return;
        setState((prevState) => ({
          data: discardDataOnError ? null : prevState.data,
          hasData: discardDataOnError ? false : prevState.hasData,
          loading: false,
          error,
        }));
        onError(error);
        onFinish(error, null);
      },
    );

    return () => {
      mounted = false;
      if (abortPreviousRequest) {
        abortController.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return state;
}

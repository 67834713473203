import React from "react";
import { Flex, Box, Button, FlexProps, Heading } from "rebass";
import { Label, Input } from "@rebass/forms";
import { useCallback, useState } from "react";
import { useApiResponse } from "../hooks/useApiResponse";
import { useAuthStore } from "../stores/AuthStore";
import api from "../util/endpoints";
import { FormattedMessage } from "react-intl";
import { ErrorBox } from "./ErrorBox";
import { validatePassword } from "../util/misc";
import { TitleBarWrapper } from "./NavBarWrapper";
import { PageLoader } from "./PageLoader";

const FlexForm: React.ComponentType<FlexProps> = (Flex as any).withComponent('form')

export function ChangePasswordForm({ resetToken }: { resetToken?: string | null }) {
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [attempt, setAttempt] = useState<{} | null>(null);

  const [currentPasswordFocus, setCurrentPasswordFocus] = useState<boolean>(true);
  const [passwordFocus, setPasswordFocus] = useState<boolean>(true);
  const [confirmPasswordFocus, setConfirmPasswordFocus] = useState<boolean>(true);
  const onCurrentPasswordFocus = useCallback(() => { setCurrentPasswordFocus(true) }, []);
  const onCurrentPasswordBlur = useCallback(() => { setCurrentPasswordFocus(false) }, []);
  const onPasswordFocus = useCallback(() => { setPasswordFocus(true) }, []);
  const onPasswordBlur = useCallback(() => { setPasswordFocus(false) }, []);
  const onConfirmPasswordFocus = useCallback(() => { setConfirmPasswordFocus(true) }, []);
  const onConfirmPasswordBlur = useCallback(() => { setConfirmPasswordFocus(false) }, []);

  const auth = useAuthStore();

  const requestState = useApiResponse(
    () =>
      attempt
        ? resetToken
          ? api.resetPassword(resetToken, password)
          : auth.authApi.changePassword(currentPassword, password)
        : null,
    [attempt], // Intentionately not including currentPassword and password to deps
  );

  const verifyState = useApiResponse(() => (resetToken ? api.validateResetPasswordToken(resetToken) : null), [
    resetToken,
  ]);

  const onChangeCurrentPassword = useCallback(evt => setCurrentPassword(evt.target.value), []);
  const onChangePassword = useCallback(evt => setPassword(evt.target.value), []);
  const onChangeConfirmPassword = useCallback(evt => setConfirmPassword(evt.target.value), []);

  const onSubmit = useCallback((evt) => {
    evt.preventDefault();
    setAttempt(() => ({}));
  }, []);

  const currentPasswordIsValid = resetToken != null || currentPassword !== "";
  const passwordIsValid = validatePassword(password);
  const confirmPasswordIsValid = password === confirmPassword;
  const formIsValid = currentPasswordIsValid && passwordIsValid && confirmPasswordIsValid;
  const currentPasswordError = !(currentPasswordIsValid || currentPasswordFocus);
  const passwordError = !(passwordIsValid || passwordFocus);
  const confirmPasswordError = !(confirmPasswordIsValid || confirmPasswordFocus);

  const username = resetToken ? verifyState.data?.username : auth.userRequest.data?.username;

  const done = requestState.data != null;

  return (
    <Flex flexDirection="column" alignItems="center">
      <FlexForm
        flexDirection="column"
        alignItems="stretch"
        maxWidth={500}
        sx={{
          width: "100%",
        }}
        px={2}
        py={3}
        onSubmit={onSubmit}
      >
        <Heading textAlign="center" color="primary" mb={3}>
          <FormattedMessage id="change-password.title" defaultMessage="Change your password" />
        </Heading>
        <ErrorBox>{verifyState.error}</ErrorBox>
        {verifyState.loading && <PageLoader />}
        {verifyState.data != null &&
          (done ? (
            <Box mt={2} mb={3} sx={{ textAlign: "center" }}>
              <FormattedMessage
                id="change-password.success"
                defaultMessage="Your password has been changed successfully!"
              />
            </Box>
          ) : (
            <>
              {/* For password managers to figure out the username: */}
              {username && <input id="username" type="hidden" value={username} />}
              {!resetToken && (
                <>
                  <Label htmlFor="current-password" sx={{ color: currentPasswordError ? "error" : undefined }}>
                    <FormattedMessage id="change-password.current-password.label" defaultMessage="Current password:" />
                  </Label>
                  <Input
                    id="current-password"
                    type="password"
                    autoComplete="current-password"
                    value={currentPassword}
                    onChange={onChangeCurrentPassword}
                    onFocus={onCurrentPasswordFocus}
                    onBlur={onCurrentPasswordBlur}
                    sx={{ borderColor: currentPasswordError ? "error" : undefined }}
                  />
                </>
              )}
              {currentPasswordError && currentPassword !== "" && (
                <Box sx={{ color: "error", fontSize: 1 }} mb={1}>
                  <FormattedMessage
                    id="change-password.current-password.error"
                    defaultMessage="Enter your current password"
                  />
                </Box>
              )}

              <Label htmlFor="password" sx={{ marginTop: 1, color: passwordError ? "error" : undefined }} mt={2}>
                <FormattedMessage id="change-password.password.label" defaultMessage="New password:" />
              </Label>
              <Input
                id="password"
                type="password"
                autoComplete="new-password"
                value={password}
                onChange={onChangePassword}
                minLength={6}
                onFocus={onPasswordFocus}
                onBlur={onPasswordBlur}
                sx={{ borderColor: passwordError ? "error" : undefined }}
              />
              {passwordError && password !== "" && (
                <Box sx={{ color: "error", fontSize: 1 }} mb={1}>
                  <FormattedMessage
                    id="change-password.password.error"
                    defaultMessage="Password must be at least 6 characters long"
                  />
                </Box>
              )}

              <Label
                htmlFor="confirm-password"
                sx={{ marginTop: 1, color: confirmPasswordError ? "error" : undefined }}
                mt={2}
              >
                <FormattedMessage id="change-password.confirm-password.label" defaultMessage="Confirm password:" />
              </Label>
              <Input
                id="confirm-password"
                type="password"
                autoComplete="new-password"
                value={confirmPassword}
                onChange={onChangeConfirmPassword}
                onFocus={onConfirmPasswordFocus}
                onBlur={onConfirmPasswordBlur}
                sx={{ borderColor: confirmPasswordError ? "error" : undefined }}
              />
              {confirmPasswordError && confirmPassword !== "" && (
                <Box sx={{ color: "error", fontSize: 1 }} mb={1}>
                  <FormattedMessage
                    id="change-password.confirm-password.error"
                    defaultMessage="Passwords don't match"
                  />
                </Box>
              )}

              <Button mt={3} mb={2} variant="primary" type="submit" disabled={!formIsValid || requestState.loading}>
                <FormattedMessage id="change-password.button.submit" defaultMessage="Change password" />
              </Button>
              <ErrorBox>{requestState.error}</ErrorBox>
            </>
          ))}
      </FlexForm>
    </Flex>
  );
}

export const ChangePasswordPage = () => (
  <TitleBarWrapper title={<FormattedMessage id="change-password.title" defaultMessage="Change your password" />}>
    <ChangePasswordForm />
  </TitleBarWrapper>
);

import React, { ReactNode, useCallback, useLayoutEffect } from "react";
import { Box, Flex } from "rebass";

import config from "../util/config";
import gameInterface, { requestHidePage } from "../util/gameInterface";

export function PageContainer({ shown, children }: { children: ReactNode, shown: boolean }) {
  const onClickOutside = useCallback(evt => {
    evt.stopPropagation();
    evt.preventDefault();
    requestHidePage();
  }, []);

  useLayoutEffect(() => {
    if (config.environment === "game") {
      gameInterface.pushGameMessage("unhide_view", null);
    }
  }, []);

  if (config.environment === "web") {
    return (
      <>
        <Box
          onClick={onClickOutside}
          sx={{
            position: "absolute",
            left: 0,
            top: 0,
            bottom: 0,
            right: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            transition: "all 0.2s linear",
            opacity: shown ? 1 : 0,
          }}
        />
        <Flex
          flexDirection="column"
          alignItems="stretch"
          sx={{
            position: "absolute",
            left: "50%",
            top: "50%",
            width: "100%",
            height: "100%",
            maxWidth: "400px",
            maxHeight: "700px",

            backgroundColor: "background",
            borderRadius: "8px",
            boxShadow: "modal",
            overflow: "hidden",

            transition: "all 0.2s ease",
            opacity: shown ? 1 : 0,
            transform: shown ? "translate(-50%, -50%) scale(1, 1)" : "translate(-50%, -50%) scale(0.9, 0.9)",
          }}
        >
          <Box
            sx={{
              flexBasis: 1,
              flexShrink: 1,
              flexGrow: 1,
              overflowY: "auto",
            }}
          >
            {children}
          </Box>
        </Flex>
      </>
    );
  }

  return (
    <Flex flexDirection="column" alignItems="stretch" width="100%">
      {children}
    </Flex>
  );
}

import React from "react";
import { Flex, FlexProps } from "rebass";

export const Section = (props: FlexProps) => (
  <Flex
    flexDirection="column"
    alignItems="stretch"
    my={2}
    mx={1}
    {...props}
    sx={{
      backgroundColor: "muted",
      borderRadius: 12,
      borderWidth: 2,
      borderColor: "secondary",
      borderStyle: "solid",
      ...props.sx,
    }}
  />
);

export const SectionTitle = ({
  expandedBottom,
  expandedTop,
  ...props
}: FlexProps & { expandedBottom?: boolean; expandedTop?: boolean }) => (
  <Flex
    p={2}
    {...props}
    sx={{
      flexDirection: "row",
      backgroundColor: "secondary",
      color: "textLight",
      borderTopLeftRadius: expandedTop ? 0 : 8,
      borderTopRightRadius: expandedTop ? 0 : 8,
      borderBottomLeftRadius: expandedBottom ? 0 : 8,
      borderBottomRightRadius: expandedBottom ? 0 : 8,
      fontWeight: "heading",
      ...props.sx,
    }}
  />
);

import { faCheckCircle, faPlus, faTrash, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { Box, Flex, FlexProps } from "rebass";
import { useApiInvocationRunner, useApiResponse } from "../hooks/useApiResponse";
import { useAuthStore } from "../stores/AuthStore";
import { User } from "../types/User";
import { AuthenticatedAPI } from "../util/endpoints";
import { ErrorBox } from "./ErrorBox";
import { Section, SectionTitle } from "./Section";

const SectionRow = (props: FlexProps) => (
  <Flex
    p={2}
    flexDirection="row"
    {...props}
    sx={{
      color: "primary",
      "&:nth-child(even)": {
        backgroundColor: "highlight",
      },
      ...props.sx,
    }}
  />
);

const UserRow = ({ user, onDelete, onAccept }: { user: User; onAccept?: () => void; onDelete: () => void }) => (
  <SectionRow p={0}>
    <Box p={2}>
      <FontAwesomeIcon icon={faUser} size="lg" />
    </Box>
    <Box py={2} flex={1}>
      {user.firstName} {user.lastName}
    </Box>
    {onAccept && (
      <Box p={2} sx={{ color: "primary", cursor: "pointer", "&:hover": { opacity: 0.8 } }} onClick={onAccept}>
        <FontAwesomeIcon icon={faCheckCircle} size="lg" />
      </Box>
    )}
    <Box p={2} sx={{ color: "error", cursor: "pointer", "&:hover": { opacity: 0.8 } }} onClick={onDelete}>
      <FontAwesomeIcon icon={faTrash} size="lg" />
    </Box>
  </SectionRow>
);

function FriendsSection({ authApi, userId }: { authApi: AuthenticatedAPI, userId: string }) {
  const [refreshToken, setRefreshToken] = useState(0);
  const response = useApiResponse(() => authApi.getFriends(userId), [authApi, refreshToken]);
  const friendRequestsResponse = useApiResponse(() => authApi.getIncomingFriendRequests(userId), [authApi, refreshToken]);
  const invocationRunner = useApiInvocationRunner();
  const history = useHistory();

  return (
    <>
      {friendRequestsResponse.data && friendRequestsResponse.data.items.length > 0 && (
        <Section>
          <SectionTitle expandedBottom>
            <FormattedMessage id="friends.requests.title" defaultMessage="Friend requests" />
          </SectionTitle>
          <Flex flexDirection="column">
            {friendRequestsResponse.data.items.map((user) => (
              <UserRow
                key={user.id}
                user={user}
                onAccept={() => {
                  invocationRunner(authApi.confirmIncomingFriendRequest(userId, user.id)).then(() => {
                    setRefreshToken((x) => x + 1);
                  });
                }}
                onDelete={() => {
                  invocationRunner(authApi.deleteFriend(userId, user.id)).then(() => {
                    setRefreshToken((x) => x + 1);
                  });
                }}
              />
            ))}
          </Flex>
        </Section>
      )}
      <ErrorBox m={1}>{friendRequestsResponse.error}</ErrorBox>
      {response.data && (
        <Section>
          <SectionTitle expandedBottom>
            <FormattedMessage id="friends.list.title" defaultMessage="Friends" />
          </SectionTitle>
          <Flex flexDirection="column">
            {response.data.items.length === 0 ? (
              <SectionRow sx={{ textAlign: "center" }}>
                <FormattedMessage id="friends.list.empty" defaultMessage="You haven't added any friends" />
              </SectionRow>
            ) : (
              response.data.items.map((user) => (
                <UserRow
                  key={user.id}
                  user={user}
                  onDelete={() => {
                    invocationRunner(authApi.deleteFriend(userId, user.id)).then(() => {
                      setRefreshToken((x) => x + 1);
                    });
                  }}
                />
              ))
            )}
          </Flex>
          <SectionTitle
            expandedTop
            sx={{ textAlign: "center", cursor: "pointer", alignItems: "center", justifyContent: "center" }}
            onClick={() => {
              history.push("/friends/add-friend");
            }}
          >
            <Box mr={1}>
              <FormattedMessage id="friends.list.add" defaultMessage="Add friend" />
            </Box>
            <FontAwesomeIcon icon={faPlus} />
          </SectionTitle>
        </Section>
      )}
      <ErrorBox m={1}>{response.error}</ErrorBox>
    </>
  );
}

function SupervisorSection({ authApi, userId }: { authApi: AuthenticatedAPI, userId: string }) {
  const [refreshToken, setRefreshToken] = useState(0);
  const response = useApiResponse(() => authApi.getSupervisors(userId), [authApi, refreshToken]);
  const invocationRunner = useApiInvocationRunner();
  const history = useHistory();

  if (!response.data) { return null; }

  return (
    <>
      <Section>
        <SectionTitle expandedBottom>
          <FormattedMessage id="supervisors.list.title" defaultMessage="Parents, psychologists and researchers" />
        </SectionTitle>
        <Flex flexDirection="column">
          {response.data.items.length === 0 ? (
            <SectionRow sx={{ textAlign: "center" }}>
              <FormattedMessage id="supervisors.list.empty" defaultMessage="You haven't added any parents or psychologists" />
            </SectionRow>
          ) : (
            response.data.items.map((user) => (
              <UserRow
                key={user.id}
                user={user}
                onDelete={() => {
                  invocationRunner(authApi.deleteSupervisor(userId, user.id)).then(() => {
                    setRefreshToken((x) => x + 1);
                  });
                }}
              />
            ))
          )}
        </Flex>
        <SectionTitle
          expandedTop
          sx={{ textAlign: "center", cursor: "pointer", alignItems: "center", justifyContent: "center" }}
          onClick={() => {
            history.push("/friends/add-supervisor");
          }}
        >
          <Box mr={1}>
            <FormattedMessage id="supervisors.list.add" defaultMessage="Add a parent, psychologist or researcher" />
          </Box>
          <FontAwesomeIcon icon={faPlus} />
        </SectionTitle>
      </Section>
      <ErrorBox m={1}>{response.error}</ErrorBox>
    </>
  );
}

export function CodeSection({ title, code }: { title: ReactNode; code: string }) {
  return (
    <Section>
      <SectionTitle expandedBottom>{title}</SectionTitle>
      <Box my={2} sx={{ textAlign: "center", fontWeight: "heading", fontSize: 5, color: "primary" }}>
        {code}
      </Box>
    </Section>
  );
}

export function FriendsPage() {
  const { authApi, userId, userRequest } = useAuthStore();
  if (!authApi || !userId) { return null; }
  return (
    <>
      {userRequest.data?.friendCode && (
        <CodeSection
          title={<FormattedMessage id="friend.code.title" defaultMessage="Friend Code" />}
          code={userRequest.data?.friendCode}
        />
      )}
      <FriendsSection authApi={authApi} userId={userId} />
      <SupervisorSection authApi={authApi} userId={userId} />
    </>
  );
}

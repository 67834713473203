import React, { PropsWithChildren, ReactNode, useCallback } from "react";
import { Flex, FlexProps } from "rebass";

type Props = {
  labelTrue: ReactNode,
  labelFalse: ReactNode,
  labelNull?: ReactNode,
  labelUndefined?: ReactNode,
  value: boolean | null | undefined,
  onChange: (value: boolean | null | undefined) => void,
}

function SegmentButtonSegment({
  active,
  onClick,
  children,
}: PropsWithChildren<{ active: boolean; onClick: () => void }>) {
  return (
    <Flex
      sx={{
        alignItems: "center",
        justifyContent: "center",
        p: 1,
        color: "textLight",
        fontSize: "0.8em",
        whiteSpace: "nowrap",
        flexShrink: 0,
        backgroundColor: active ? "secondary" : "primary",
        cursor: active ? "default" : "pointer",
        userSelect: "none",
        "&:first-of-type": {
          borderTopLeftRadius: 8,
          borderBottomLeftRadius: 8,
        },
        "&:last-of-type": {
          borderTopRightRadius: 8,
          borderBottomRightRadius: 8,
        },
      }}
      onClick={onClick}
    >
      {children}
    </Flex>
  );
}

export function SegmentButton(props: Props & Omit<FlexProps, keyof Props>) {
  const { onChange, labelTrue, labelFalse, labelNull, labelUndefined, value, ...flexProps } = props;
  const onClickTrue = useCallback(() => { onChange(true); }, [onChange]);
  const onClickFalse = useCallback(() => { onChange(false); }, [onChange]);
  const onClickNull = useCallback(() => { onChange(null); }, [onChange]);
  const onClickUndefined = useCallback(() => { onChange(undefined); }, [onChange]);

  return (
    <Flex flexDirection="row" {...flexProps}>
      {labelUndefined && (
        <SegmentButtonSegment active={value === undefined} onClick={onClickUndefined}>
          {labelUndefined}
        </SegmentButtonSegment>
      )}
      {labelNull && (
        <SegmentButtonSegment active={value === null} onClick={onClickNull}>
          {labelNull}
        </SegmentButtonSegment>
      )}
      <SegmentButtonSegment active={value === false} onClick={onClickFalse}>
        {labelFalse}
      </SegmentButtonSegment>
      <SegmentButtonSegment active={value === true} onClick={onClickTrue}>
        {labelTrue}
      </SegmentButtonSegment>
    </Flex>
  );
}

import React from "react";
import { Box, Button, Flex } from "rebass";
import { useCallback, useState } from "react";
import { useApiResponse } from "../../hooks/useApiResponse";
import { useAuthStore } from "../../stores/AuthStore";
import { FormattedMessage } from "react-intl";
import { TitleBarWrapper } from "../NavBarWrapper";

import "date-input-polyfill-react";
import { GlobalSettingsData } from "../../types/GlobalSettings";
import { ErrorBox } from "../ErrorBox";
import { PageLoader } from "../PageLoader";
import { Section, SectionTitle } from "../Section";
import { levelIndexToName, LevelName } from "../../types/Score";
import { Checkbox, Input, Label } from "@rebass/forms";
import { UserRole } from "../../types/User";
import { toggleInArray } from "../../util/misc";

const levels = [LevelName.TOTAL, ...levelIndexToName];
const roles = Object.values(UserRole);

export function GlobalSettingsFormInner() {
  const [patch, setPatch] = useState<Partial<GlobalSettingsData>>({});
  const [attempt, setAttempt] = useState<object | null>(null);

  const authData = useAuthStore();
  const authApi = authData.authApi;

  const requestState = useApiResponse(() => authApi.getGlobalSettings(), [authApi]);

  const submitRequestState = useApiResponse(
    () => (attempt && requestState.data ? authApi.setGlobalSettings({ ...requestState.data, ...patch }) : null),
    [attempt, authApi], // Intentionately not including patch to deps
  );

  const settings = { ...requestState.data, ...patch };
  const { defaultRoles = [], scoreAverages = {}, lowScores = {}, scoreAveragesChild = {}, lowScoresChild = {} } = settings;

  const onSubmit = useCallback((evt) => {
    evt.preventDefault();
    setAttempt(() => ({}));
  }, []);

  const onChangeDefaultRole = (role: UserRole) => (evt: any) => {
    const value = evt.target.checked;
    setPatch((p) => ({ ...p, defaultRoles: toggleInArray(p.defaultRoles ?? defaultRoles, role, value) }));
  };

  const onChangeAverage = (levelName: LevelName) => (evt: any) => {
    const value = parseFloat(evt.target.value);
    if (isFinite(value)) {
      setPatch(p => ({ ...p, scoreAverages: { ...(p.scoreAverages ?? scoreAverages), [levelName]: value } }));
    }
  }

  const onChangeAverageChild = (levelName: LevelName) => (evt: any) => {
    const value = parseFloat(evt.target.value);
    if (isFinite(value)) {
      setPatch(p => ({ ...p, scoreAveragesChild: { ...(p.scoreAveragesChild ?? scoreAveragesChild), [levelName]: value } }));
    }
  }

  const onChangeLowScores = (levelName: LevelName) => (evt: any) => {
    const value = parseFloat(evt.target.value);
    if (isFinite(value)) {
      setPatch(p => ({ ...p, lowScores: { ...(p.lowScores ?? lowScores), [levelName]: value } }));
    }
  }

  const onChangeLowScoresChild = (levelName: LevelName) => (evt: any) => {
    const value = parseFloat(evt.target.value);
    if (isFinite(value)) {
      setPatch(p => ({ ...p, lowScoresChild: { ...(p.lowScoresChild ?? lowScoresChild), [levelName]: value } }));
    }
  }

  const onChangeDisablePurchases = (evt: any) => {
    const value = evt.target.checked;
    if (isFinite(value)) {
      setPatch(p => ({ ...p, disablePurchases: !!value }))
    }
  }

  if (requestState.loading) {
    return <PageLoader />;
  }

  if (requestState.error) {
    return <ErrorBox>{requestState.error}</ErrorBox>
  }

  if (!requestState.data) {
    return null;
  }

  return (
    <>
      <Section mx={0}>
        <SectionTitle expandedBottom>
          <FormattedMessage id="global_settings.default_roles" defaultMessage="Newly created user roles" />
        </SectionTitle>
        {roles.map((role) => (
          <Label pl={2} py={1} key={role} flexDirection="row" alignItems="center" sx={{ display: "flex" }}>
            <Box flex={1}>{role}</Box>
            <Checkbox checked={defaultRoles.includes(role)} onChange={onChangeDefaultRole(role)} />
          </Label>
        ))}
      </Section>
      <Section mx={0}>
        <SectionTitle expandedBottom>
          <FormattedMessage id="global_settings.average_scores" defaultMessage="Average level scores" />
        </SectionTitle>
        {levels.map((levelName) => (
          <Flex px={2} py={1} key={levelName} flexDirection="row" alignItems="center">
            <Label htmlFor={`score-${levelName}`}>{levelName}</Label>
            <Input
              id={`score-${levelName}`}
              type="number"
              width={100}
              value={scoreAverages[levelName] ?? 0}
              onChange={onChangeAverage(levelName)}
            />
          </Flex>
        ))}
      </Section>
      <Section mx={0}>
        <SectionTitle expandedBottom>
          <FormattedMessage id="global_settings.average_scores.kids" defaultMessage="Average level scores (<18yo)" />
        </SectionTitle>
        {levels.map((levelName) => (
          <Flex px={2} py={1} key={levelName} flexDirection="row" alignItems="center">
            <Label htmlFor={`score-${levelName}`}>{levelName}</Label>
            <Input
              id={`score-${levelName}`}
              type="number"
              width={100}
              value={scoreAveragesChild[levelName] ?? 0}
              onChange={onChangeAverageChild(levelName)}
            />
          </Flex>
        ))}
      </Section>
      <Section mx={0}>
        <SectionTitle expandedBottom>
          <FormattedMessage id="global_settings.low_scores" defaultMessage="Recommended level score treshold" />
        </SectionTitle>
        {levelIndexToName.map((levelName) => (
          <Flex px={2} py={1} key={levelName} flexDirection="row" alignItems="center">
            <Label htmlFor={`score-${levelName}`}>{levelName}</Label>
            <Input
              id={`score-${levelName}`}
              type="number"
              width={100}
              value={lowScores[levelName] ?? 0}
              onChange={onChangeLowScores(levelName)}
            />
          </Flex>
        ))}
      </Section>
      <Section mx={0}>
        <SectionTitle expandedBottom>
          <FormattedMessage id="global_settings.low_scores.kids" defaultMessage="Recommended level score treshold (<18yo)" />
        </SectionTitle>
        {levelIndexToName.map((levelName) => (
          <Flex px={2} py={1} key={levelName} flexDirection="row" alignItems="center">
            <Label htmlFor={`score-${levelName}`}>{levelName}</Label>
            <Input
              id={`score-${levelName}`}
              type="number"
              width={100}
              value={lowScoresChild[levelName] ?? 0}
              onChange={onChangeLowScoresChild(levelName)}
            />
          </Flex>
        ))}
      </Section>
      <Section mx={0}>
        <SectionTitle expandedBottom>
          <FormattedMessage id="global_settings.other_settings" defaultMessage="Other settings" />
        </SectionTitle>
        <Label pl={2} py={1} flexDirection="row" alignItems="center" sx={{ display: "flex" }}>
          <Box flex={1}>
            <FormattedMessage id="global_settings.disable_purchases" defaultMessage="Disable purchases" />
          </Box>
          <Checkbox checked={settings.disablePurchases || false} onChange={onChangeDisablePurchases} />
        </Label>
      </Section>
      <Button onClick={onSubmit} disabled={submitRequestState.loading}>
        <FormattedMessage id="button.save" defaultMessage="Save" />
      </Button>
      <ErrorBox>{submitRequestState.error}</ErrorBox>
    </>
  );
}

function GlobalSettingsForm() {
    return (
      <Flex flexDirection="column" alignItems="center">
        <Flex flexDirection="column" alignItems="stretch" maxWidth={500} width="100%" px={2} py={3}>
          <GlobalSettingsFormInner />
        </Flex>
      </Flex>
    );
}

export function GlobalSettingsPage() {
  return <TitleBarWrapper title={<FormattedMessage id="page.title.global_settings" defaultMessage="Global settings" />}>
    <GlobalSettingsForm />
  </TitleBarWrapper>;
}

import { Input } from "@rebass/forms";
import React, { ReactNode, useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { Box, Button, Flex, Heading } from "rebass";
import { useApiInvocationRunner } from "../hooks/useApiResponse";
import { useAuthStore } from "../stores/AuthStore";
import { APIInvocation } from "../util/api";
import { AuthenticatedAPI } from "../util/endpoints";
import { ErrorBox } from "./ErrorBox";
import { TitleBarWrapper } from "./NavBarWrapper";

const AddCodePage = ({
  getInvocation,
  title,
  heading,
  description,
  submitLabel,
}: {
  getInvocation: (authApi: AuthenticatedAPI, userId: string, friendCode: string) => APIInvocation<any>;
  title: ReactNode;
  heading: ReactNode;
  description: ReactNode;
  submitLabel: ReactNode;
}) => {
  const [code, setCode] = useState("");
  const invocationRunner = useApiInvocationRunner();

  const onCodeChange = useCallback(evt => {
    setCode(evt.target.value);
  }, []);

  const { authApi, userId } = useAuthStore();

  const [error, setError] = useState<Error | null>(null);
  const history = useHistory();

  const onSubmit = useCallback(
    (evt) => {
      evt.stopPropagation();
      evt.preventDefault();
      if (!code || !authApi || !userId) return;

      invocationRunner(getInvocation(authApi, userId, code)).then(
        () => {
          setError(null);
          history.goBack();
        },
        (err) => {
          setError(err);
        },
      );
    },
    [authApi, userId, code, getInvocation, invocationRunner, history],
  );

  return (
    <TitleBarWrapper title={title}>
      <form onSubmit={onSubmit}>
        <Flex flexDirection="column" p={2} sx={{ textAlign: "center", alignItems: "stretch" }}>
          <Heading mt={2} mb={1} >{heading}</Heading>
          <Box mb={3}>{description}</Box>
          <Input mb={1} value={code} onChange={onCodeChange} />
          <Button mb={2} type="submit">
            {submitLabel}
          </Button>
          <ErrorBox>{error}</ErrorBox>
        </Flex>
      </form>
    </TitleBarWrapper>
  );
}

export const AddFriendPage = () => (
  <AddCodePage
    getInvocation={(authApi, userId, friendCode) => authApi.sendFriendRequest(userId, friendCode)}
    title={<FormattedMessage id="friends.add.title" defaultMessage="Add friend" />}
    heading={<FormattedMessage id="friends.add.heading" defaultMessage="Send a friend request" />}
    description={
      <FormattedMessage
        id="friends.add.description"
        defaultMessage="Type in your friend's Friend Code in here. After they accept your friend request, you'll be able to see your friend's scores."
      />
    }
    submitLabel={<FormattedMessage id="friends.add.submit_label" defaultMessage="Send request" />}
  />
);

export const AddSupervisorPage = () => (
  <AddCodePage
    getInvocation={(authApi, userId, friendCode) => authApi.addSupervisor(userId, friendCode)}
    title={<FormattedMessage id="supervisors.add.title" defaultMessage="Add parent, psychologist or researcher" />}
    heading={<FormattedMessage id="supervisors.add.heading" defaultMessage="Add a parent, psychologist or researcher" />}
    description={
      <FormattedMessage
        id="supervisors.add.description"
        defaultMessage="Add your parent, psychologist or researcher as your supervisor by typing their Friend Code here. They'll be able to see your scores and recommend levels for you."
      />
    }
    submitLabel={<FormattedMessage id="supervisors.add.submit_label" defaultMessage="Add" />}
  />
);


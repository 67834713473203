import React from "react";
import { FormattedMessage } from "react-intl";
import { Box } from "rebass";
import { User } from "../../types/User";
import { AuthenticatedAPI } from "../../util/endpoints";

export type SidebarTabProps = {
  selectedUsers: string[];
  authUser: User;
  authApi: AuthenticatedAPI;
};

export function MultiselectNotSupported() {
    return (
      <Box mx={2} my={3} sx={{ textAlign: "center" }}>
        <FormattedMessage id="admin.profile.multiselect" defaultMessage="Can only view a single user at a time" />
      </Box>
    );
}

import React from "react";
import { Flex, Box, Button, FlexProps, Heading } from "rebass";
import { Label, Input } from "@rebass/forms";
import { useCallback, useState } from "react";
import { useApiResponse } from "../hooks/useApiResponse";
import api from "../util/endpoints";
import { FormattedMessage } from "react-intl";
import { ErrorBox } from "./ErrorBox";
import { validateEmail } from "../util/misc";
import { useLanguageStore } from "../stores/LanguageStore";
import { TitleBarWrapper } from "./NavBarWrapper";

const FlexForm: React.ComponentType<FlexProps> = (Flex as any).withComponent('form')

export function ForgotPasswordPage() {
  const [email, setEmail] = useState<string>("");
  const [attempt, setAttempt] = useState<{ languages: readonly string[] } | null>(null);
  const lang = useLanguageStore();

  const onChangeEmail = useCallback(evt => setEmail(evt.target.value), []);

  const requestState = useApiResponse(() => (attempt ? api.sendResetPasswordEmail(email, attempt.languages) : null), [
    attempt,
  ]);

  const onSubmit = useCallback((evt) => {
    evt.preventDefault();
    setAttempt(() => ({ languages: lang.languages }));
  }, [lang.languages]);

  const formIsValid = validateEmail(email)

  return (
    <TitleBarWrapper>
      <Flex flexDirection="column" alignItems="center">
        <FlexForm
          flexDirection="column"
          alignItems="stretch"
          maxWidth={500}
          sx={{
            width: "100%",
          }}
          px={2}
          py={3}
          onSubmit={onSubmit}
        >
          <Heading textAlign="center" color="primary">
            <FormattedMessage id="forgot-password.cta.header" defaultMessage="Forgot password" />
          </Heading>
          <Box
            mt={2}
            mb={3}
            sx={{
              textAlign: "center",
            }}
          >
            {requestState.data ? (
              <FormattedMessage
                id="forgot-password.success.description"
                defaultMessage="Check your email! If the email you gave us is correct, you should have a password reset link in your inbox."
              />
            ) : (
              <FormattedMessage
                id="forgot-password.cta.description"
                defaultMessage="Enter the email address associated with your account and, if the email is correct, we will send you a password reset link."
              />
            )}
          </Box>

          {!requestState.data && (
            <>
              <Label htmlFor="email">
                <FormattedMessage id="forgot-password.email.label" defaultMessage="E-mail:" />
              </Label>
              <Input id="email" type="email" autoComplete="email" value={email} onChange={onChangeEmail} />

              <Button mt={3} mb={2} variant="primary" type="submit" disabled={!formIsValid || requestState.loading}>
                <FormattedMessage id="password-reset.button.submit" defaultMessage="Send reset email" />
              </Button>

              <ErrorBox>{requestState.error}</ErrorBox>
            </>
          )}
        </FlexForm>
      </Flex>
    </TitleBarWrapper>
  );
}

import { User } from "../types/User";
import { APIRequestState } from "../util/api";
import config from "../util/config";
import api, { AuthenticatedAPI } from "../util/endpoints";
import gameInterface from "../util/gameInterface";
import { loadValue, saveValue } from "../util/storage";
import { Store, useStore } from "./Store"

export type AuthStoreData = {
  authToken: string | null;
  userId: string | null;
  userRequest: APIRequestState<User>;
  authApi: AuthenticatedAPI;
  source: "game" | "internal";
}

export type AuthStoreSavedData = {
  authToken: string | null;
  userId: string | null;
}

type SetAuthPayload = {
  authToken: string | null;
  userId: string | null;
  user: User | null;
}

const unauthenticatedApi = api.getAuthenticatedApi({
  authToken: "INVALID_TOKEN",
  userId: "INVALID_USER",
})

export class AuthStore extends Store<AuthStoreData> {
  data: AuthStoreData = {
    authToken: null,
    userId: null,
    userRequest: {
      data: null,
      hasData: false,
      loading: false,
      error: null,
    },
    authApi: unauthenticatedApi,
    source: "internal",
  };

  constructor() {
    super();

    this.subscribe((data) => {
      const { authToken, userId, userRequest, source } = data;
      if (source === "game") return;
      gameInterface.pushGameMessage("auth", {
        authToken,
        userId,
        user: userRequest.data,
        userJson: userRequest.data && JSON.stringify(userRequest.data),
      });
      saveValue<AuthStoreSavedData>("rethinkAuth", authToken && userId ? { authToken, userId } : undefined);
    });

    if (config.environment === "web" || config.environment === "admin") {
      const storedValues = loadValue<AuthStoreSavedData>("rethinkAuth");
      if (storedValues) {
        this.setAuthData(storedValues.authToken, storedValues.userId);
      }
    }

    gameInterface.subscribeToWebMessage<SetAuthPayload>("set_auth", (payload) => {
      this.setAuthData(payload.authToken, payload.userId, payload.user, "game");
    });
  }

  userRequestAbort: AbortController | null = null;

  setAuthData(
    authToken: string | null,
    userId: string | null,
    user: User | null = null,
    source: "game" | "internal" = "internal",
  ) {
    const { data } = this;
    if (authToken === data.authToken && userId === data.userId && user === data.userRequest.data) return;

    this.userRequestAbort?.abort();
    this.userRequestAbort = null;

    const authApi = authToken && userId ? api.getAuthenticatedApi({ authToken, userId }) : unauthenticatedApi;

    if (!userId || user) {
      this.setData({
        authToken,
        userId,
        userRequest: {
          data: user,
          hasData: true,
          loading: false,
          error: null,
        },
        authApi,
        source,
      });
      return;
    }

    this.setData({
      authToken,
      userId,
      userRequest: {
        data: null,
        hasData: false,
        loading: true,
        error: null,
      },
      authApi,
      source,
    });

    this.userRequestAbort = new AbortController();
    authApi
      .getUser(userId)(this.userRequestAbort.signal)
      .then(
        (user) => {
          this.setData({
            authToken,
            userId,
            userRequest: {
              data: user,
              hasData: true,
              loading: false,
              error: null,
            },
            authApi,
            source: "internal",
          });
        },
        (error) => {
          this.setData({
            authToken,
            userId,
            userRequest: {
              data: null,
              hasData: false,
              loading: false,
              error,
            },
            authApi,
            source: "internal",
          });
        },
      );
  }

  signOut = (evt?: any) => {
    evt?.preventDefault();
    this.setAuthData(null, null, null);
  }
}

const authStore = new AuthStore();
export default authStore;

export const useAuthStore = (): AuthStoreData => {
  return useStore(authStore);
}

import React, { useCallback, useState } from "react";
import { SidebarTabProps } from "./SidebarUtils";
import { Button, Flex } from "rebass";
import { useApiResponse } from "../../hooks/useApiResponse";
import { FormattedMessage } from "react-intl";
import { ErrorBox } from "../ErrorBox";
import { PageLoader } from "../PageLoader";
import { Section, SectionTitle } from "../Section";
import { Label } from "@rebass/forms";
import { UserRole } from "../../types/User";
import { toggleInArray } from "../../util/misc";
import { SegmentButton } from "../SegmentButton";

const roles = Object.values(UserRole);

function RolesSidebarTabInner({ authApi, authUser, selectedUsers }: SidebarTabProps) {
  const [patch, setPatch] = useState<{ add: UserRole[], remove: UserRole[] }>({ add: [], remove: [] });
  const [attempt, setAttempt] = useState<object | null>(null);

  const requestState = useApiResponse(() => (selectedUsers.length !== 1 ? null : authApi.getUser(selectedUsers[0])), [
    authApi,
    selectedUsers[0],
    selectedUsers.length,
  ]);

  const multiple = selectedUsers.length !== 1;

  const submitRequestState = useApiResponse(
    () =>
      attempt
        ? {
            invocation: authApi.patchRoles(selectedUsers, patch),
            onSuccess: () => {
              if (multiple) setPatch({ add: [], remove: [] });
            },
          }
        : null,
    [attempt, authApi], // Intentionately not including patch to deps
  );

  const onSubmit = useCallback((evt) => {
    evt.preventDefault();
    setAttempt(() => ({}));
  }, []);

  const onChangeRole = (role: UserRole) => (value: boolean | null | undefined) => {
    setPatch(({ add, remove }) => ({
      add: toggleInArray(add, role, value === true),
      remove: toggleInArray(remove, role, value === false),
    }));
  };

  if (requestState.loading) {
    return <PageLoader />;
  }

  if (requestState.error) {
    return <ErrorBox>{requestState.error}</ErrorBox>
  }

  return (
    <>
      <Section mx={0}>
        <SectionTitle expandedBottom>
          <FormattedMessage id="admin.roles.title" defaultMessage="User roles" />
        </SectionTitle>
        {roles.map((role) => (
          <Flex px={2} key={role} flexDirection="row" alignItems="center">
            <Label htmlFor={`role-${role}`}>{role}</Label>
            <SegmentButton
              my={1}
              sx={{ flexShrink: 0 }}
              id={`role-${role}`}
              value={
                (patch.add.includes(role) ? true : patch.remove.includes(role) ? false : undefined) ??
                requestState.data?.roles?.includes(role)
              }
              onChange={onChangeRole(role)}
              labelTrue={
                multiple ? (
                  <FormattedMessage id="segment.add" defaultMessage="Add" />
                ) : (
                  <FormattedMessage id="segment.enabled" defaultMessage="Enabled" />
                )
              }
              labelFalse={
                multiple ? (
                  <FormattedMessage id="segment.remove" defaultMessage="Remove" />
                ) : (
                  <FormattedMessage id="segment.disabled" defaultMessage="Disabled" />
                )
              }
              labelUndefined={multiple && <FormattedMessage id="segment.keep" defaultMessage="No change" />}
            />
          </Flex>
        ))}
      </Section>
      <Button onClick={onSubmit} disabled={submitRequestState.loading}>
        <FormattedMessage id="button.save" defaultMessage="Save" />
      </Button>
      <ErrorBox>{submitRequestState.error}</ErrorBox>
    </>
  );
}

export function RolesSidebarTab(props: SidebarTabProps) {
  return (
    <Flex flexDirection="column" m={2} alignItems="stretch">
      <RolesSidebarTabInner key={props.selectedUsers.join(",")} {...props} />
    </Flex>
  );
}

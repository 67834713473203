import gameInterface from "../util/gameInterface";
import { Store, useStore } from "./Store"

export type VersionStoreData = {
  version: string | null,
  engineVersion: string | null,
  engineHash: string | null,
  debug: boolean,
};

type SetVersionPayload = VersionStoreData;

export class VersionStore extends Store<VersionStoreData> {
  data: VersionStoreData = {
    version: process.env.REACT_APP_GAME_VERSION || null,
    engineVersion: process.env.REACT_APP_ENGINE_VERSION || null,
    engineHash: process.env.REACT_APP_ENGINE_HASH || null,
    debug: process.env.NODE_ENV === "development",
  };

  constructor() {
    super();

    gameInterface.subscribeToWebMessage<SetVersionPayload>("set_version", (versionData) => {
      this.setData(versionData);
    });
  }
}

const versionStore = new VersionStore();
export default versionStore;

export const useVersionStore = (): VersionStoreData => {
  return useStore(versionStore);
}

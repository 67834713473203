import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import React, { ReactNode, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Box, Flex } from "rebass";
import { useApiResponse } from "../../hooks/useApiResponse";
import { levelIndexToName, LevelName } from "../../types/Score";
import { AuthenticatedAPI } from "../../util/endpoints";
import { LevelTitle } from "../LevelTitle";
import { Section, SectionTitle } from "../Section";
import { MultiselectNotSupported, SidebarTabProps } from "./SidebarUtils";

const QuizHistory = ({ timestamps, breakdowns }: { timestamps: number[], breakdowns: number[][] }) => {
  const items: ReactNode[] = [];

  for (let i = timestamps.length - 1; i >= 0; i -= 1) {
    const timestamp = timestamps[i];
    const breakdown = breakdowns[i];
    const date = new Date(timestamp);
    items.push(
      <Flex
        key={i}
        flexDirection="column"
        alignItems="stretch"
        sx={{
          borderBottomStyle: "solid",
          borderBottomWidth: 1,
          borderBottomColor: "fadedGray",
        }}
      >
        <Box p={1}>{format(date, "yyyy-MM-dd HH:mm:ss O")}</Box>
        <Flex p={1} flexDirection="row" alignItems="flex-start" flexWrap="wrap">
          {breakdown.map((value, index) => (
            <Flex
              key={index}
              alignItems="center"
              justifyContent="center"
              sx={{
                color: "textLight",
                backgroundColor: value ? "confirm" : "reject",
                px: 2,
                height: 34,
                minWidth: 34,
                "&:first-of-type": {
                  borderTopLeftRadius: 8,
                  borderBottomLeftRadius: 8,
                },
                "&:last-of-type": {
                  borderTopRightRadius: 8,
                  borderBottomRightRadius: 8,
                },
              }}
            >
              {index + 1}
            </Flex>
          ))}
        </Flex>
      </Flex>,
    );
  }

  if (items.length === 0) {
    return (
      <Flex p={2} sx={{ alignItems: "center", justifyContent: "center", textAlign: "center" }}>
        <FormattedMessage id="admin.quizes.empty" defaultMessage="No quizzes filled for this level" />
      </Flex>
    );
  }

  return <>{items}</>;
}

const QuizSection = ({
  expanded,
  onClick,
  levelIndex,
  levelName,
  userId,
  authApi,
}: {
  levelIndex: number;
  levelName: LevelName;
  expanded: boolean;
  userId: string;
  onClick: () => void;
  authApi: AuthenticatedAPI;
}) => {
  const scoreHistory = useApiResponse(
    () =>
      userId
        ? {
            invocation: expanded ? authApi.getScoreHistory(userId, levelName, true) : null,
            discardPreviousData: false,
          }
        : null,
    [userId, authApi, expanded, levelName],
  );

  return (
    <Section>
      <SectionTitle
        pl={1}
        expandedBottom={expanded}
        sx={{ cursor: expanded ? "initial" : "pointer" }}
        onClick={onClick}
      >
        <Box flex={1}>
          <LevelTitle index={levelIndex} />
        </Box>
        <Box sx={{ flexShrink: 0 }} ml={1}>
          <FontAwesomeIcon fixedWidth icon={expanded ? faChevronDown : faChevronRight} />
        </Box>
      </SectionTitle>
      {expanded && scoreHistory.data && (
        <QuizHistory timestamps={scoreHistory.data.timestamps} breakdowns={scoreHistory.data.breakdowns || []} />
      )}
    </Section>
  );
};

function Quizes({ authApi, authUser, selectedUsers }: SidebarTabProps) {
  const userId = selectedUsers[0];
  const [expandedLevel, setExpandedLevel] = useState<LevelName | null>(null);

  return (
    <>
      {levelIndexToName.map((bareLevelName, levelIndex) => {
        const levelName: LevelName = (bareLevelName + "_QUIZ") as any;
        if (LevelName[levelName] == null) return null;
        return <QuizSection
          key={levelName}
          levelName={levelName}
          levelIndex={levelIndex}
          expanded={expandedLevel === levelName}
          onClick={() => { setExpandedLevel(levelName); }}
          userId={userId}
          authApi={authApi}
        />;
      })}
    </>
  );
}

export function QuizesSidebarTab(props: SidebarTabProps) {
  if (props.selectedUsers.length > 1) {
    return <MultiselectNotSupported />;
  }

  return <Flex flexDirection="column" m={2} alignItems="stretch">
    <Quizes {...props} />
  </Flex>
}

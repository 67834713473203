import { faFileCsv, faInfoCircle, faListAlt, faMedal, faUserCog, faWrench, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode, useState } from "react";
import { useIntl } from "react-intl";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { Box, Flex } from "rebass";
import { useDimensions } from "../../hooks/useDimensions";
import { useAuthStore } from "../../stores/AuthStore";
import { UserRole } from "../../types/User";
import { OverridesSidebarTab } from "./OverridesSidebarTab";
import { ProfileSidebarTab } from "./ProfileSidebarTab";
import { QuizesSidebarTab } from "./QuizesSidebarTab";
import { ReportsSidebarTab } from "./ReportsSidebarTab";
import { RolesSidebarTab } from "./RolesSidebarTab";
import { ScoresSidebarTab } from "./ScoresSidebarTab";
import { UserList } from "./UserList";

const FlexLink: React.ComponentType<any> = (Flex as any).withComponent(Link);

export function SidebarNavButton({ icon, path, title }: { icon: IconDefinition; path: string; title?: string }) {
  return (
    <Route path={path}>
      {({ match }) => (
        <FlexLink
          sx={{
            alignItems: "center",
            justifyContent: "center",
            width: 50,
            height: 44,
            color: "textLight",
            backgroundColor: match ? "secondary" : "primary",
            "&:first-of-type": {
              borderTopLeftRadius: 8,
              borderBottomLeftRadius: 8,
            },
            "&:last-of-type": {
              borderTopRightRadius: 8,
              borderBottomRightRadius: 8,
            }
          }}
          to={path}
          title={title}
        >
          <FontAwesomeIcon icon={icon} size="lg" />
        </FlexLink>
      )}
    </Route>
  )
}

function UserSidebar({ selectedUsers }: { selectedUsers: string[] }) {
  const intl = useIntl();
  const authStore = useAuthStore();
  const authUser = authStore.userRequest.data;
  const roles = authUser?.roles ?? [];

  if (!authUser) return null;

  const props = {
    authApi: authStore.authApi,
    authUser,
    selectedUsers,
  };

  return (
    <>
      <Flex flexDirection="row" flexShrink={0} my={2} justifyContent="center" alignItems="center">
        <SidebarNavButton
          path="/users/profile"
          icon={faInfoCircle}
          title={intl.formatMessage({ id: "admin.scores.profile", defaultMessage: "Profile" })}
        />
        <SidebarNavButton
          path="/users/scores"
          icon={faMedal}
          title={intl.formatMessage({ id: "admin.scores.title", defaultMessage: "Scores" })}
        />
        <SidebarNavButton
          path="/users/quizes"
          icon={faListAlt}
          title={intl.formatMessage({ id: "admin.quizes.title", defaultMessage: "Quizes" })}
        />
        <SidebarNavButton
          path="/users/overrides"
          icon={faWrench}
          title={intl.formatMessage({ id: "admin.overrides.title", defaultMessage: "Level overrides" })}
        />
        {roles.includes(UserRole.ADMIN) && (
          <SidebarNavButton
            path="/users/roles"
            icon={faUserCog}
            title={intl.formatMessage({ id: "admin.roles.title", defaultMessage: "User roles" })}
          />
        )}
        {roles.includes(UserRole.REPORTS) && (
          <SidebarNavButton
            path="/users/reports"
            icon={faFileCsv}
            title={intl.formatMessage({ id: "admin.overrides.title", defaultMessage: "Level overrides" })}
          />
        )}
      </Flex>
      <Box flex={1} sx={{ overflowY: "auto" }}>
        <Switch>
          <Route path="/users/profile">
            <ProfileSidebarTab {...props} />
          </Route>
          <Route path="/users/scores">
            <ScoresSidebarTab {...props} />
          </Route>
          <Route path="/users/quizes">
            <QuizesSidebarTab {...props} />
          </Route>
          <Route path="/users/overrides">
            <OverridesSidebarTab {...props} />
          </Route>
          {roles.includes(UserRole.ADMIN) && (
            <Route path="/users/roles">
              <RolesSidebarTab {...props} />
            </Route>
          )}
          {roles.includes(UserRole.REPORTS) && (
            <Route path="/users/reports">
              <ReportsSidebarTab {...props} />
            </Route>
          )}
          <Route>
            <Redirect to="/users/profile" />
          </Route>
        </Switch>
      </Box>
    </>
  );
}

function UserSidebarWrapper({ children }: { children: ReactNode }) {
  const [ref, { width = "40vw" }] = useDimensions();
  return (
    <Box flex={1} minWidth={320} ref={ref}>
      <Flex
        flexDirection="column"
        sx={{
          position: "fixed",
          width,
          top: 50,
          right: 0,
          height: "calc(100vh - 50px)",
          backgroundColor: "background",
          boxShadow: "modal",
          alignItems: "stretch",
        }}
      >
        {children}
      </Flex>
    </Box>
  );
}

export function UsersPage() {
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const authStore = useAuthStore();

  if (!authStore.userRequest.data) return null;

  return (
    <Flex flexDirection="row">
      <UserList selectedUsers={selectedUsers} onSelectedUsersChange={setSelectedUsers} />
      {selectedUsers.length > 0 && (
        <UserSidebarWrapper>
          <UserSidebar selectedUsers={selectedUsers} />
        </UserSidebarWrapper>
      )}
    </Flex>
  );
}

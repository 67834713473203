import React, { useEffect } from "react";
import { HashRouter, MemoryRouter, Redirect, Route, Switch } from "react-router-dom";
import { useAuthStore } from "../stores/AuthStore";
import { UserRole } from "../types/User";
import config from "../util/config";
import { requestHidePage } from "../util/gameInterface";
import { AddFriendPage, AddSupervisorPage } from "./AddFriendPage";
import { FriendsPage } from "./FriendsPage";
import { IntlWrapper } from "./IntlWrapper";
import { LicensesPage } from "./LicensesPage";
import { NavBarWrapper } from "./NavBarWrapper";
import NavigationMessageHandler from "./NavigationMessageHandler";
import { PaywallPage } from "./PaywallPage";
import { PrivacyPolicyPage } from "./PrivacyPolicyPage";
import { ProfilePage } from "./ProfileForm";
import { RequireAuth } from "./RequireAuth";
import { ScoresPage } from "./ScoresPage";
import { SettingsPage } from "./SettingsPage";
import { UsersPage } from "./admin/UsersPage";
import { GlobalSettingsPage } from "./admin/GlobalSettingsPage";
import { TermsPage } from "./TermsPage";
import { DisclaimerPage } from "./DisclaimerPage";
import { ForgotPasswordPage } from "./ForgotPasswordPage";
import { ChangePasswordForm, ChangePasswordPage } from "./ChangePasswordPage";
import { DeleteAccountPage } from "./DeleteAccountPage";

export function InstantClose() {
  useEffect(() => {
    requestHidePage();
  });
  return <></>;
}

export function Routes() {
  const Router: React.ComponentType = config.environment === "admin" ? HashRouter : MemoryRouter;

  const auth = useAuthStore();
  const user = auth.userRequest.data;
  const roles = user?.roles || [];

  return (
    <Router>
      <IntlWrapper>
        <NavigationMessageHandler>
          <Switch>
            <Route path="/licenses" exact>
              <LicensesPage />
            </Route>
            <Route path="/privacy" exact>
              <PrivacyPolicyPage />
            </Route>
            <Route path="/terms" exact>
              <TermsPage />
            </Route>
            <Route path="/disclaimer" exact>
              <DisclaimerPage />
            </Route>
            <Route path="/forgot-password" exact>
              <ForgotPasswordPage />
            </Route>
            <Route
              path="/reset-password/:token"
              exact
              render={({ match }) => <ChangePasswordForm resetToken={match.params.token} />}
            ></Route>
            <Route>
              <RequireAuth>
                <Switch>
                  <Route path="/login">
                    <InstantClose />
                  </Route>
                  <Route path="/paywall">
                    <PaywallPage />
                  </Route>
                  {roles.includes(UserRole.PLAYER) && (
                    <Route path="/scores">
                      <NavBarWrapper>
                        <ScoresPage />
                      </NavBarWrapper>
                    </Route>
                  )}
                  <Route path="/friends" exact>
                    <NavBarWrapper>
                      <FriendsPage />
                    </NavBarWrapper>
                  </Route>
                  <Route path="/settings/profile" exact>
                    <ProfilePage />
                  </Route>
                  <Route path="/settings/global" exact>
                    <GlobalSettingsPage />
                  </Route>
                  <Route path="/settings/change-password" exact>
                    <ChangePasswordPage />
                  </Route>
                  <Route path="/settings/delete-account" exact>
                    <DeleteAccountPage/>
                  </Route>
                  <Route path="/settings" exact>
                    <NavBarWrapper>
                      <SettingsPage />
                    </NavBarWrapper>
                  </Route>
                  <Route path="/friends/add-friend" exact>
                    <AddFriendPage />
                  </Route>
                  <Route path="/friends/add-supervisor" exact>
                    <AddSupervisorPage />
                  </Route>
                  {(roles.includes(UserRole.SUPERVISOR) || roles.includes(UserRole.ADMIN)) && (
                    <Route path="/users">
                      <NavBarWrapper>
                        <UsersPage />
                      </NavBarWrapper>
                    </Route>
                  )}
                  <Route>
                    {roles.includes(UserRole.SUPERVISOR) || roles.includes(UserRole.ADMIN) ? (
                      <Redirect to="/users" />
                    ) : roles.includes(UserRole.PLAYER) ? (
                      <Redirect to="/scores" />
                    ) : (
                      <Redirect to="/settings" />
                    )}
                  </Route>
                </Switch>
              </RequireAuth>
            </Route>
          </Switch>
        </NavigationMessageHandler>
      </IntlWrapper>
    </Router>
  );
}

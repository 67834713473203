export { validate as validateEmail } from "email-validator"

export const toggleInArray = <T>(array: T[], item: T, enabled: boolean): T[] =>
  enabled ? (array.includes(item) ? [...array] : [...array, item]) : array.filter((x) => x !== item);

export const validateUsername = (s: string | null | undefined): boolean =>
  s != null && typeof s === "string" && s.length >= 5 && s.length <= 24 && /^[a-zA-Z0-9._-]+$/.test(s);

export const validatePassword = (s: string | null | undefined): boolean =>
  s != null && typeof s === "string" && s.length >= 6;

import React, { ReactNode, useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Box, Button, Flex, Text } from "rebass";
import { useApiResponse } from "../../hooks/useApiResponse";
import { User, UserRole } from "../../types/User";
import { AuthenticatedAPI } from "../../util/endpoints";
import { ErrorBox } from "../ErrorBox";
import { PageLoader } from "../PageLoader";
import { getAge, getGenderIcon, getGenderName, ProfileFormInner } from "../ProfileForm";
import { MultiselectNotSupported, SidebarTabProps } from "./SidebarUtils";

const ProfileItem = ({ label, value }: { label: ReactNode; value: ReactNode | null | undefined }) => (
  <Box mb={2}>
    <Text fontWeight="bold">{label}</Text>
    {value == null || value === "" ? (
      <Text sx={{ opacity: 0.4 }}>
        <FormattedMessage id="profile.unset" defaultMessage="Not set" />
      </Text>
    ) : (
      <Text>{value}</Text>
    )}
  </Box>
);

function ReadOnlyProfile({ user }: { user: User }) {
  const intl = useIntl();
  return (
    <Flex flexDirection="column" m={2} alignItems="stretch">
      <ProfileItem
        label={<FormattedMessage id="profile.username" defaultMessage="Username" />}
        value={user.username}
      />
      <ProfileItem
        label={<FormattedMessage id="profile.email" defaultMessage="E-mail address" />}
        value={user.email}
      />
      <ProfileItem
        label={<FormattedMessage id="profile.first-name" defaultMessage="First Name" />}
        value={user.firstName}
      />
      <ProfileItem
        label={<FormattedMessage id="profile.last-name" defaultMessage="Last Name" />}
        value={user.lastName}
      />
      <ProfileItem
        label={<FormattedMessage id="profile.birth-day" defaultMessage="Birth Date" />}
        value={user.birthDay}
      />
      <ProfileItem
        label={<FormattedMessage id="admin.users.age" defaultMessage="Age" />}
        value={getAge(user.birthDay)}
      />
      <ProfileItem
        label={<FormattedMessage id="profile.gender" defaultMessage="Gender" />}
        value={user.gender == null ? undefined : `${getGenderIcon(user.gender)} ${getGenderName(user.gender, intl)}`}
      />
      <ProfileItem
        label={<FormattedMessage id="admin.users.purchased" defaultMessage="Purchased full game" />}
        value={user.purchasedFullVersion ? "✅" : "❌"}
      />
    </Flex>
  );
}

function ProfileSidebarSingleUserTab({
  authApi,
  authUser,
  userId,
}: {
  authApi: AuthenticatedAPI;
  authUser: User;
  userId: string;
}) {
  const userRequest = useApiResponse(() => authApi.getUser(userId), [authApi]);
  const [patchedUser, setPatchedUser] = useState<User | null>(null);

  const [editing, setEditing] = useState<boolean>(false);
  const onToggleEdit = useCallback(() => setEditing(x => !x), []);

  return (
    <>
      <ErrorBox>{userRequest.error}</ErrorBox>
      {!userRequest.data && userRequest.loading && <PageLoader />}
      {userRequest.data && (
        <>
          {editing ? (
            <ProfileFormInner
              maxWidth={null}
              authApi={authApi}
              user={patchedUser || userRequest.data}
              onUserChange={setPatchedUser}
            />
          ) : (
            <ReadOnlyProfile user={userRequest.data} />
          )}
          {(authUser.roles ?? []).includes(UserRole.ADMIN) && (
            <Flex width="100%" p={1} flexDirection="column" alignItems="stretch">
              <Button onClick={onToggleEdit} variant="secondary">
                {editing ? (
                  <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
                ) : (
                  <FormattedMessage id="button.edit" defaultMessage="Edit" />
                )}
              </Button>
            </Flex>
          )}
        </>
      )}
    </>
  );
}

export function ProfileSidebarTab({ authApi, authUser, selectedUsers }: SidebarTabProps) {
  if (selectedUsers.length > 1) {
    return <MultiselectNotSupported />;
  }

  return (
    <ProfileSidebarSingleUserTab
      key={selectedUsers[0]}
      userId={selectedUsers[0]}
      authApi={authApi}
      authUser={authUser}
    />
  );
}

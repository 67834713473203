export enum LevelName {
  TOTAL = 'TOTAL',
  LEVEL1 = 'LEVEL1',
  LEVEL2 = 'LEVEL2',
  LEVEL3 = 'LEVEL3',
  LEVEL4 = 'LEVEL4',
  LEVEL5 = 'LEVEL5',
  LEVEL6 = 'LEVEL6',
  LEVEL7 = 'LEVEL7',
  LEVEL1_QUIZ = 'LEVEL1_QUIZ',
  LEVEL2_QUIZ = 'LEVEL2_QUIZ',
  LEVEL3_QUIZ = 'LEVEL3_QUIZ',
  LEVEL4_QUIZ = 'LEVEL4_QUIZ',
  LEVEL5_QUIZ = 'LEVEL5_QUIZ',
  LEVEL6_QUIZ = 'LEVEL6_QUIZ',
  LEVEL7_QUIZ = 'LEVEL7_QUIZ',
}
export const levelNameToIndex: Partial<Record<LevelName, number>> = {
  [LevelName.LEVEL1]: 0,
  [LevelName.LEVEL2]: 1,
  [LevelName.LEVEL3]: 2,
  [LevelName.LEVEL4]: 3,
  [LevelName.LEVEL5]: 4,
  [LevelName.LEVEL6]: 5,
  [LevelName.LEVEL7]: 6,
};

export const levelIndexToName: LevelName[] = [];
for (const levelName_ in levelNameToIndex) {
  const levelName = (levelName_ as unknown) as LevelName;
  const levelIndex = levelNameToIndex[levelName];
  if (levelIndex != null) {
    levelIndexToName[levelIndex] = levelName;
  }
}

export type Score = {
  level: LevelName;
  timestamp: number;
  value: number;
  breakdown: Array<number | null>;
}

export function scoreBreakdownAtIndex(score: Score | null | undefined, index: number): number | undefined {
  if (!score) return undefined;
  if (index === -1) return score.value;
  const breakdownScore = score.breakdown && score.breakdown[index];
  return breakdownScore == null ? undefined : breakdownScore;
}

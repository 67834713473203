import authStore from "../stores/AuthStore";
import languageStore from "../stores/LanguageStore";
import versionStore from "../stores/VersionStore";
import gameInterface from "./gameInterface";
import { TarWriter } from "./tarball";

type Dump = {
  fsData: any[];
};

async function prepareDump(): Promise<Dump> {
  const request = window.indexedDB.open("/data");
  const db: IDBDatabase = await new Promise((resolve, reject) => {
    request.onerror = reject;
    request.onblocked = reject;
    request.onsuccess = () => {
      resolve(request.result);
    };
  })

  const cursor = db.transaction("FILE_DATA", "readonly")
    .objectStore("FILE_DATA")
    .openCursor();

  const fsData: Array<any> = [];
  await new Promise<void>((resolve, reject) => {
    cursor.onerror = reject;
    cursor.onsuccess = (evt) => {
      const cur = cursor.result;
      if (cur) {
        fsData.push({ ...cur.value, key: cur.primaryKey });
        cur.continue();
      } else {
        resolve();
      }
    };
  })

  return { fsData };
}

function dump(data: Dump, error: Error) {
  const tar = new TarWriter();

  const meta = {
    userId: authStore.data.userId,
    language: languageStore.data,
    version: versionStore.data,
    userAgent: navigator.userAgent,
    error: error ? `${error.name}: ${error.message}\n${error.stack}` : undefined,
  };
  tar.addTextFile("metadata.json", JSON.stringify(meta, null, 2));

  data.fsData.forEach(file => {
    const contents: Uint8Array = file.contents;
    if (!contents) return;
    console.assert(contents.byteOffset === 0 && contents.byteLength === contents.buffer.byteLength, "Buffer slices unsupported");
    const fileName = file.key.replace(/^\//, '')
    tar.addFileArrayBuffer(fileName, contents.buffer);
  })

  tar.download("rethink_dump.tar")
}

gameInterface.subscribeToWebMessage("dump", async () => {
  let data: Dump = { fsData: [] };
  let error: Error;
  try {
    data = await prepareDump();
  } catch (err) {
    error = err;
  }

  const button = document.createElement("button");
  button.innerHTML = "Download debug dump";
  button.style.position = "fixed";
  button.style.top = "0";
  button.style.left = "0";
  button.style.right= "0";
  button.style.bottom = "0";
  document.body.appendChild(button);

  button.onclick = () => {
    button.remove();
    dump(data, error);
  };
});

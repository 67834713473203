import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js";
import "abortcontroller-polyfill/dist/polyfill-patch-fetch";

import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "emotion-theming";

import { Routes } from "./components/Routes";
import theme from "./util/theme";
import "./index.css";

import "./util/dump";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

import { faCaretLeft, faCaretRight, faSpinner, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Label } from "@rebass/forms";
import React, { useCallback, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Box, Button, Flex } from "rebass";
import { useApiResponse } from "../../hooks/useApiResponse";
import { useAuthStore } from "../../stores/AuthStore";
import { UserRole } from "../../types/User";
import { ErrorBox } from "../ErrorBox";
import { PageLoader } from "../PageLoader";
import { getAge, getGenderIcon } from "../ProfileForm";
import { Table, TableBody, TableDataCell, TableHeader, TableHeaderCell, TableRow } from "./Table";

type Props = {
  selectedUsers: string[],
  onSelectedUsersChange: (action: React.SetStateAction<string[]>) => void,
}

const pageTake = 100;

export function UserList({ selectedUsers, onSelectedUsersChange }: Props) {
  const authStore = useAuthStore();
  const { authApi } = authStore;
  const authUserId = authStore.userRequest?.data?.id ?? "";
  const roles = authStore.userRequest?.data?.roles ?? [];
  const isAdmin = roles.includes(UserRole.ADMIN);
  const [showSupervised, setShowSupervised] = useState(false);

  const [skip, setSkip] = useState(0);
  const [attempt, setAttempt] = useState(0);

  const response = useApiResponse(
    () => ({
      invocation: showSupervised
        ? authApi.getSupervisedUsers(authUserId, skip, pageTake)
        : authApi.getUsers(skip, pageTake),
      discardPreviousData: [authApi, skip, showSupervised, authUserId],
    }),
    [authApi, skip, showSupervised, authUserId, attempt],
  );

  const selectedSet = useMemo(() => new Set(selectedUsers), [selectedUsers]);
  const pageSelected = useMemo(() => {
    if (!response.data) { return false; }
    let state;
    for (const user of response.data.items) {
      if (state === undefined) {
        state = selectedSet.has(user.id);
        continue;
      }
      if (selectedSet.has(user.id) !== state) {
        return "mixed";
      }
    }
    if (state === undefined) return false;
    return state;
  }, [selectedSet, response.data]);

  const onPageSelect = useCallback((evt) => {
    const checked = evt.target.checked;
    const ids = (response.data?.items || []).map(u => u.id);
    onSelectedUsersChange((userIds) =>
      checked ? [...userIds, ...ids.filter((id) => !userIds.includes(id))] : userIds.filter((id) => !ids.includes(id)),
    );
  }, [response.data, onSelectedUsersChange])

  const onCheckboxClick = (clickedId: string) => (evt: any) => {
    const checked = evt.target.checked;
    onSelectedUsersChange((userIds) =>
      checked
        ? userIds.includes(clickedId)
          ? userIds
          : [...userIds, clickedId]
        : userIds.filter((id) => id !== clickedId),
    );
  }

  const onRowClick = (clickedId: string) => (evt: any) => {
    onSelectedUsersChange((userIds) => {
      if (userIds.length === 1 && userIds[0] !== clickedId) {
        return [clickedId];
      }
      return userIds.includes(clickedId) ? userIds.filter((id) => id !== clickedId) : [...userIds, clickedId];
    })
  }

  const onReload = useCallback(() => {
    setAttempt((x) => x + 1);
  }, []);

  const onClearSelection = useCallback(() => onSelectedUsersChange([]), [onSelectedUsersChange]);

  const onNextPage = useCallback(() => setSkip((x) => x + pageTake), []);
  const onPreviousPage = useCallback(() => setSkip(x => x - pageTake), []);

  const CheckboxDuck = (Checkbox as any) as React.ComponentType<any>;

  return (
    <Flex flex={1.5} flexDirection="column" py={1} px={3}>
      <ErrorBox>{response.error}</ErrorBox>
      <Flex flexDirection="row">
        {isAdmin && (
          <Label style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={showSupervised}
              onChange={(evt) => {
                setShowSupervised((evt.target as any).checked);
              }}
            />
            <FormattedMessage id="admin.users.show-supervised-only" defaultMessage="Show only users supervised by me" />
          </Label>
        )}
      </Flex>
      {response.data && (
        <>
          <Flex sx={{ height: 44, alignItems: "center" }}>
            {selectedUsers.length > 0 && (
              <>
                <Button variant="secondary" mr={2} onClick={onClearSelection}>
                  <FormattedMessage id="list.clear-selection" defaultMessage="Clear selection" />
                </Button>
                <Box>
                  <FormattedMessage
                    id="list.selected"
                    defaultMessage="{count} items selected"
                    values={{ count: selectedUsers.length }}
                  />
                </Box>
              </>
            )}
            <Flex flex={1} />
            <Box>
              {response.data.items.length !== response.data.totalCount || response.data.skip !== 0 ? (
                <>
                  <FormattedMessage
                    id="list.paginated"
                    defaultMessage="{from}-{to} of {count} items"
                    values={{
                      from: response.data.skip + 1,
                      to: response.data.skip + response.data.items.length,
                      count: response.data.totalCount,
                    }}
                  />
                  <Button variant="outline" mx={1} onClick={onPreviousPage} disabled={skip <= 0}>
                    <FontAwesomeIcon icon={faCaretLeft} />
                  </Button>
                  <Button
                    variant="outline"
                    mx={1}
                    onClick={onNextPage}
                    disabled={skip + response.data.items.length >= response.data.totalCount}
                  >
                    <FontAwesomeIcon icon={faCaretRight} />
                  </Button>
                </>
              ) : (
                <FormattedMessage
                  id="list.count"
                  defaultMessage="{count} items"
                  values={{ count: response.data.items.length }}
                />
              )}
              <Button variant="outline" mx={1} onClick={onReload}>
                {response.loading ? <FontAwesomeIcon icon={faSpinner} spin /> : <FontAwesomeIcon icon={faSync} />}
              </Button>
            </Box>
          </Flex>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>
                  <Label>
                    <CheckboxDuck
                      sx={{ cursor: "pointer" }}
                      checked={pageSelected === "mixed" ? false : pageSelected}
                      indeterminate={pageSelected === "mixed"}
                      onChange={onPageSelect}
                    />
                  </Label>
                </TableHeaderCell>
                <TableHeaderCell>
                  <FormattedMessage id="admin.users.username" defaultMessage="Username" />
                </TableHeaderCell>
                <TableHeaderCell>
                  <FormattedMessage id="admin.users.email" defaultMessage="E-mail" />
                </TableHeaderCell>
                <TableHeaderCell>
                  <FormattedMessage id="admin.users.name" defaultMessage="Name" />
                </TableHeaderCell>
                <TableHeaderCell>
                  <FormattedMessage id="admin.users.age" defaultMessage="Age" />
                </TableHeaderCell>
                <TableHeaderCell>
                  <FormattedMessage id="admin.users.gender" defaultMessage="Gender" />
                </TableHeaderCell>
                {roles.includes(UserRole.ADMIN) && (
                  <TableHeaderCell>
                    <FormattedMessage id="admin.users.roles" defaultMessage="Roles" />
                  </TableHeaderCell>
                )}
              </TableRow>
            </TableHeader>
            <TableBody>
              {response.data.items.map((user) => (
                <TableRow key={user.id}>
                  <TableDataCell>
                    <Label>
                      <Checkbox
                        sx={{ cursor: "pointer" }}
                        checked={selectedSet.has(user.id)}
                        onChange={onCheckboxClick(user.id)}
                      />
                    </Label>
                  </TableDataCell>
                  <TableDataCell onClick={onRowClick(user.id)} sx={{ cursor: "pointer" }}>
                    {user.username}
                  </TableDataCell>
                  <TableDataCell onClick={onRowClick(user.id)} sx={{ cursor: "pointer" }}>
                    {user.email}
                  </TableDataCell>
                  <TableDataCell onClick={onRowClick(user.id)} sx={{ cursor: "pointer" }}>
                    {user.firstName} {user.lastName}
                  </TableDataCell>
                  <TableDataCell>
                    {getAge(user.birthDay) ?? ""}
                  </TableDataCell>
                  <TableDataCell>{user.gender && getGenderIcon(user.gender)}</TableDataCell>
                  {roles.includes(UserRole.ADMIN) && <TableDataCell>{(user.roles ?? []).join(", ")}</TableDataCell>}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}
      {response.loading && <PageLoader />}
    </Flex>
  );
}

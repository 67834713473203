import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { Box, Button, Flex } from "rebass";
import authStore, { useAuthStore } from "../stores/AuthStore";
import { useVersionStore } from "../stores/VersionStore";
import { UserRole } from "../types/User";
import config from "../util/config";

export function SettingsPage() {
  const history = useHistory();

  const navToProfile = useCallback(() => {
    history.push("/settings/profile")
  }, [history]);

  const navToPrivacyPolicy = useCallback(() => {
    history.push("/privacy")
  }, [history]);

  const navToTerms = useCallback(() => {
    history.push("/terms")
  }, [history]);

  const navToDisclaimer = useCallback(() => {
    history.push("/disclaimer")
  }, [history]);

  const navToLicenses = useCallback(() => {
    history.push("/licenses")
  }, [history]);

  const navToPaywall = useCallback(() => {
    history.push("/paywall")
  }, [history]);

  const navToChangePassword = useCallback(() => {
    history.push("/settings/change-password")
  }, [history]);

  const navToDeleteAccount = useCallback(() => {
    history.push("/settings/delete-account")
  }, [history]);

  const navToGlobalSettings = useCallback(() => {
    history.push("/settings/global")
  }, [history]);

  const navToGame = useCallback(() => {
    window.location.href = "https://game.rethinkplatform.org";
  }, []);

  const auth = useAuthStore();

  const user =auth.userRequest?.data;
  const roles = user?.roles ?? [];
  const showUnlock = user && (!user.purchasedFullVersion && !roles.includes(UserRole.FULL_GAME));

  const version = useVersionStore();

  return (
    <Flex flexDirection="column" alignItems="center">
      <Flex
        flexDirection="column"
        alignItems="stretch"
        maxWidth={500}
        sx={{
          width: "100%",
        }}
        px={2}
        py={2}
      >
        {showUnlock && (
          <Button mb={2} variant="primary" onClick={navToPaywall}>
            <FormattedMessage id="settings.purchase_cta" defaultMessage="Unlock full version" />
          </Button>
        )}
        <Button mb={2} variant="primary" onClick={navToProfile}>
          <FormattedMessage id="settings.profile" defaultMessage="Profile" />
        </Button>
        {roles.includes(UserRole.ADMIN) && (
          <Button mb={2} variant="secondary" onClick={navToGlobalSettings}>
            <FormattedMessage id="settings.global_settings" defaultMessage="Global settings" />
          </Button>
        )}
        <Button mb={2} variant="outline" onClick={navToPrivacyPolicy}>
          <FormattedMessage id="settings.privacy_policy" defaultMessage="Privacy policy" />
        </Button>
        <Button mb={2} variant="outline" onClick={navToTerms}>
          <FormattedMessage id="settings.terms" defaultMessage="Terms and conditions" />
        </Button>
        <Button mb={2} variant="outline" onClick={navToDisclaimer}>
          <FormattedMessage id="settings.disclaimer" defaultMessage="Disclaimer" />
        </Button>
        <Button mb={2} variant="outline" onClick={navToLicenses}>
          <FormattedMessage id="settings.software_licences" defaultMessage="Software licenses" />
        </Button>
        <Button mb={2} variant="outline" onClick={navToChangePassword}>
          <FormattedMessage id="settings.change-password" defaultMessage="Change password" />
        </Button>
        <Button mb={2} variant="outline" onClick={navToDeleteAccount}>
          <FormattedMessage id="settings.delete-account" defaultMessage="Delete account" />
        </Button>
        {config.environment === "admin" && (
          <Button mb={2} variant="outline" onClick={navToGame}>
            <FormattedMessage id="settings.game_link" defaultMessage="Open game" />
          </Button>
        )}
        <Button mb={2} variant="secondary" onClick={authStore.signOut}>
          <FormattedMessage id="settings.sign_out" defaultMessage="Sign out" />
        </Button>
        <Box
          sx={{
            textAlign: "right",
            opacity: 0.5,
            userSelect: "text",
          }}
        >
          {version.version && <Box>v{version.version}</Box>}
          {(version.engineVersion || version.engineHash) && (
            <Box>
              Defold{version.engineVersion && ` v${version.engineVersion}`} {version.engineHash?.substr(0, 6)}
            </Box>
          )}
          {version.debug && <Box>debug build</Box>}
        </Box>
      </Flex>
    </Flex>
  );
}

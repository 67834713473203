import { faChevronDown, faChevronRight, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "emotion-theming";
import React, { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Box, Flex, Text } from "rebass";
import { Line, LineChart, ReferenceLine, Tooltip, XAxis } from "recharts";
import { useApiResponse } from "../hooks/useApiResponse";
import { useDimensions } from "../hooks/useDimensions";
import { useAuthStore } from "../stores/AuthStore";
import { levelIndexToName, LevelName, levelNameToIndex, Score, scoreBreakdownAtIndex } from "../types/Score";
import { User } from "../types/User";
import { ErrorBox } from "./ErrorBox";
import { PageLoader } from "./PageLoader";
import { Section, SectionTitle } from "./Section";
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent';
import { LevelTitle } from "./LevelTitle";

const labelFormatter = (timestamp: any) => {
  return new Date(timestamp as number).toLocaleString();
};

const CustomTooltipContent = (props: any) => {
  if (props?.payload && props.payload[0]) {
    const breakdown = props.payload[0].payload.breakdown;
    if (breakdown) {
      props = {
        ...props,
        payload: [
          ...props.payload,
          {
            ...props.payload[0],
            name: "breakdown",
            dataKey: "breakdown",
            value: breakdown,
          },
        ],
      };
    }
  }
  return <DefaultTooltipContent {...props} />;
}

const ScoreHistoryGraph = ({scores, timestamps, breakdowns, average}: { scores: number[]; timestamps: number[], breakdowns?: number[][], average: number | null }) => {
  const theme: any = useTheme();
  const intl = useIntl();
  const formatter = useCallback((value, name) => {
    if (name === "score") {
      return [value, intl.formatMessage({
        id: "graph.label.score",
        defaultMessage: "Score",
      })];
    }
    if (name === "breakdown") {
      return [
        (value as number[])?.map((x) => (x == null ? "∅" : x))?.join(", "),
        intl.formatMessage({
          id: "graph.label.breakdown",
          defaultMessage: "Breakdown",
        }),
      ];
    }
    return [value, name];
  }, [intl]);

  const [ref, { width = 100 }] = useDimensions();

  return (
    <>
      <div style={{ width: "100%" }} ref={ref} />
      <LineChart
        height={200}
        width={width}
        data={timestamps.map((timestamp, i) => ({
          timestamp,
          score: scores[i],
          breakdown: breakdowns ? breakdowns[i] : undefined,
        }))}
      >
        <XAxis hide dataKey="timestamp" type="number" domain={["auto", "auto"]} scale="time" />
        {average != null && average !== 0 && (
          <ReferenceLine
            y={average}
            stroke={theme.colors.lightGray}
            strokeDasharray="3 3"
            label={{
              // @ts-ignore
              value: intl.formatMessage(
                { id: "graph.reference.average", defaultMessage: "Average: {average}" },
                { average },
              ),
              fill: theme.colors.lightGray,
              background: theme.colors.muted,
              dy: 9,
            }}
          />
        )}
        <Line type="monotone" dataKey="score" stroke={theme.colors.primary} isAnimationActive={false} />
        <Tooltip
          formatter={formatter}
          labelFormatter={labelFormatter}
          content={(props: any) => <CustomTooltipContent {...props} />}
        />
      </LineChart>
    </>
  );
};

const ScoreSection = ({
  expanded,
  onClick,
  score,
  friendScores,
  levelIndex,
  levelName,
  userId,
  showBreakdown,
  averageScore,
}: {
  score: number;
  friendScores: { user: User; score?: Score }[] | null;
  levelIndex: number;
  levelName: LevelName;
  expanded: boolean;
  userId: string;
  onClick: () => void;
  showBreakdown: boolean;
  averageScore: number | null;
}) => {
  const authStoreData = useAuthStore();
  const authApi = authStoreData.authApi;
  const scoreHistory = useApiResponse(
    () =>
      userId
        ? {
            invocation: expanded ? authApi.getScoreHistory(userId, levelName, showBreakdown) : null,
            discardPreviousData: false,
          }
        : null,
    [userId, authApi, expanded, levelName],
  );

  return (
    <Section>
      <SectionTitle
        pl={1}
        expandedBottom={expanded}
        sx={{ cursor: expanded ? "initial" : "pointer" }}
        onClick={onClick}
      >
        <Box flex={1}>
          {levelIndex === -1 ? (
            <FormattedMessage id="scores.total.title" defaultMessage="Total:" />
          ) : (
            <LevelTitle index={levelIndex} />
          )}
        </Box>
        <Box sx={{ flexShrink: 0 }}>{score}</Box>
        <Box sx={{ flexShrink: 0 }} ml={1}>
          <FontAwesomeIcon fixedWidth icon={expanded ? faChevronDown : faChevronRight} />
        </Box>
      </SectionTitle>
      {expanded && scoreHistory.data && (
        <ScoreHistoryGraph
          scores={scoreHistory.data.scores}
          timestamps={scoreHistory.data.timestamps}
          breakdowns={scoreHistory.data.breakdowns}
          average={averageScore}
        />
      )}
      {expanded && friendScores && (
        <Box>
          {friendScores.map(({ user, score }) => {
            const scoreValue = scoreBreakdownAtIndex(score, levelIndex);
            if (scoreValue == null) return null;
            return (
              <Flex
                p={2}
                flexDirection="row"
                flexWrap="wrap"
                sx={{
                  color: "primary",
                  "&:nth-child(odd)": {
                    backgroundColor: "highlight",
                  },
                }}
              >
                <FontAwesomeIcon icon={faUser} size="lg" />
                <Box flex={1} ml={2}>
                  {user.firstName} {user.lastName}
                </Box>
                <Box sx={{ flexShrink: 0 }}>{scoreValue}</Box>
              </Flex>
            );
          })}
        </Box>
      )}
    </Section>
  );
};

export function TopScoresSection(props: {}) {
  const authStoreData = useAuthStore();
  const { authApi, userId } = authStoreData;

  const topScores = useApiResponse(() => (userId ? authApi.getTopScores() : null), []);

  if (topScores.error) {
    return <ErrorBox m={1}>{topScores.error}</ErrorBox>;
  }

  if (topScores.loading) {
    return <PageLoader />;
  }

  if (!topScores.data || !userId) return null;

  return (
    <Section>
      <SectionTitle
        pl={1}
        expandedBottom={true}
      >
        <Box flex={1}>
          <FormattedMessage id="scores.top.title" defaultMessage="Top high scores:" />
        </Box>
      </SectionTitle>
      <Box>
        {topScores.data.scores.map(({ nickname, score, friendCode }, index) => {
          return (
            <Flex
              key={index}
              p={2}
              flexDirection="row"
              flexWrap="wrap"
              sx={{
                color: "primary",
                "&:nth-child(odd)": {
                  backgroundColor: "highlight",
                },
              }}
            >
              <FontAwesomeIcon icon={faUser} size="lg" />
              <Box ml={2} sx={{ fontWeight: 'bold' }}>
                #{index + 1}:
              </Box>
              <Box flex={1} ml={1}>
                {nickname}
                {friendCode != null && (
                  <Text
                    ml={1}
                    color="lightGray"
                    display="inline"
                    fontSize={1}
                  >
                    {friendCode}
                  </Text>
                )}
              </Box>
              <Box sx={{ flexShrink: 0 }}>{score}</Box>
            </Flex>
          );
        })}
      </Box>
    </Section>
  );
}

const sections = [LevelName.TOTAL, 'TOP_SCORES', ...levelIndexToName];

export function ScoresPage(props: { userId?: string, showFriends?: boolean, showBreakdown?: boolean }) {
  const authStoreData = useAuthStore();
  const authApi = authStoreData.authApi;

  const {
    userId = authStoreData.userId,
    showFriends = true,
    showBreakdown = false,
  } = props;

  const highScores = useApiResponse(() => (userId ? authApi.getHighScore(userId, LevelName.TOTAL) : null), [
    userId,
    authApi,
  ]);

  const friendScores = useApiResponse(() => (userId && showFriends ? authApi.getFriendScores(userId) : null), [
    userId,
    showFriends,
    authApi,
  ]);

  const averageScores = useApiResponse(() => (userId ? authApi.getAverageScores(userId) : null), []);

  const [expandedLevel, setExpandedLevel] = useState<LevelName>(LevelName.TOTAL);

  if (highScores.error) {
    return <ErrorBox m={1}>{highScores.error}</ErrorBox>;
  }

  if (highScores.loading) {
    return <PageLoader />;
  }

  if (!highScores.data || !userId) return null; // Should never happen

  return (
    <>
      {sections.map(key => {
        if (key === 'TOP_SCORES') {
          return <TopScoresSection />;
        }
        const levelName: LevelName = (key as any) as LevelName;
        const levelIndex = levelNameToIndex[levelName] ?? -1;
        const score = scoreBreakdownAtIndex(highScores.data?.score, levelIndex);
        if (score == null && levelName !== LevelName.TOTAL) return null;
        return <ScoreSection
          key={levelName}
          score={score ?? 0}
          friendScores={friendScores.data}
          levelName={levelName}
          levelIndex={levelIndex}
          expanded={expandedLevel === levelName}
          onClick={() => { setExpandedLevel(levelName); }}
          userId={userId}
          showBreakdown={showBreakdown}
          averageScore={(averageScores.data && averageScores.data[levelName]) ?? null}
        />;
      })}
    </>
  );
}

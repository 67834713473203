import React, { ReactNode, useEffect, useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import config from "../util/config";
import gameInterface from "../util/gameInterface";
import { PageContainer } from "./PageContainer";

export default function NavigationMessageHandler(props: { children: ReactNode }) {
  const history = useHistory();
  const [shown, setShown] = useState(false);

  useEffect(() => {
    const dispose1 = gameInterface.subscribeToWebMessage<string>("show_page", path => {
      history.push(path);
      setShown(true);
    })
    const dispose2 = gameInterface.subscribeToWebMessage<void>("hide_page", () => {
      setShown(false);
    });
    return () => {
      dispose1();
      dispose2();
    }
  })

  const [rendered, setRendered] = useState(false);
  useEffect(() => {
    if (shown) {
      setRendered(true);
    } else {
      const handle = setTimeout(() => {
        setRendered(false);
      }, 500);
      return () => { clearTimeout(handle); };
    }
  }, [shown]);

  const [delayedShown, setDelayedShown] = useState(false);
  useLayoutEffect(() => {
    const handle = requestAnimationFrame(() => {
      setDelayedShown(shown);
    });
    return () => {
      cancelAnimationFrame(handle);
    };
  }, [shown]);

  if (config.environment === "admin") return <>{props.children}</>;

  if (!shown && (!rendered || config.environment !== "web")) return null;

  return <PageContainer shown={config.environment !== "web" || delayedShown}>{props.children}</PageContainer>;
}

import React from "react";
import { Flex, Box, Button, Heading } from "rebass";
import { FormattedMessage } from "react-intl";

export default function FirstLoginPage({ name, onDismiss }: { name: string, onDismiss: () => void }) {
  return (
    <Flex flexDirection="column" alignItems="center">
      <Heading textAlign="center" color="primary" mt={3}>
        <FormattedMessage id="first_login.header" defaultMessage="Hi {name}!" values={{ name }} />
      </Heading>
      <Flex
        flexDirection="column"
        alignItems="center"
        maxWidth={500}
        sx={{
          width: "100%",
          textAlign: "center",
        }}
        px={2}
        py={3}
      >
        <Box mb={3}>
          <FormattedMessage
            id="first_login.paragraph1"
            defaultMessage="Welcome to the REThink platform!"
          />
        </Box>
        <Box mb={3}>
          <FormattedMessage
            id="first_login.paragraph2"
            defaultMessage="Through this platform you will be able to access the REThink therapeutic game after your participation gets approved by the platform admin. Your access to the REThink game can be offered free based on your assignment to a research study and thus will involve filling in a series of questionnaires before receiving access and after you play the game according to the instructions you receive."
          />
        </Box>
        <Box>
          <FormattedMessage
            id="first_login.paragraph3"
            defaultMessage="As part of this platform, you can visualise your score progress, connect with your friends who are playing the game, give access to your progress in the game to your parents or your therapist and get access to other resources, depending on your assigned study."
          />
        </Box>
        <Button mx={2} mt={3} mb={2} variant="primary" onClick={onDismiss} alignSelf="stretch">
          <FormattedMessage id="first_login.button.dismiss" defaultMessage="OK" />
        </Button>
      </Flex>
    </Flex>
  );
}

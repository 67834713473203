import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { Box, Button, Flex, Heading, Link } from "rebass";
import { useAuthStore } from "../stores/AuthStore";
import { UserRole } from "../types/User";
import config from "../util/config";
import gameInterface, { requestHidePage } from "../util/gameInterface";
import { InstantClose } from "./Routes";
import appStoreBadge from "./assets/app-store-badge.svg";
import googlePlayBadge from "./assets/google-play-badge.png";
import { useApiResponse } from "../hooks/useApiResponse";

const onBuy = () => {
  gameInterface.pushGameMessage("purchase", { sku: "org.rethinkplatform.rethink.fullgame" });
}

const openUrl = (url: string) => {
  if (config.environment === "game") {
    gameInterface.pushGameMessage("open_url", { url });
  } else {
    window.open(url, "_blank");
  }
};

const onResearchRegisterClick = () => {
  openUrl("http://app.rethinkplatform.ro");
};

export const PaywallPage = () => {
  const { userRequest, authApi } = useAuthStore();
  const settingsRequest = useApiResponse(() => authApi.getGlobalSettings(), [authApi]);

  const history = useHistory();
  const onDismiss = useCallback(() => {
    if (config.environment === "admin") {
      history.push("/settings");
    } else {
      requestHidePage();
    }
  }, [history]);

  if (!userRequest.data?.roles) return null;
  if (userRequest.data?.roles.includes(UserRole.FULL_GAME)) {
    return <InstantClose />;
  }

  if (!settingsRequest.data && !(settingsRequest.error && !settingsRequest.loading)) return null;

  if (settingsRequest.data?.disablePurchases) {
    return (
      <Flex flexDirection="column" alignItems="center">
        <Flex
          flexDirection="column"
          alignItems="center"
          maxWidth={500}
          sx={{
            width: "100%",
          }}
          px={2}
          py={3}
        >
          <Heading textAlign="center" color="primary" mt={3}>
            <FormattedMessage id="iap.disabled.cta.header" defaultMessage="Register for a research group" />
          </Heading>
          <Box
            mt={2}
            mb={3}
            mx={2}
            sx={{
              textAlign: "center",
            }}
          >
            <FormattedMessage
              id="iap.disabled.cta.description"
              defaultMessage="For now, you can have free access to the game by participating in one of our ongoing research projects."
            />
          </Box>
          <Button m={2} onClick={onResearchRegisterClick} variant="primary">
            <FormattedMessage id="iap.disabled.cta.register" defaultMessage="Register for a study" />
          </Button>
          <Button m={2} onClick={onDismiss} variant="outline">
            <FormattedMessage id="iap.cta.dismiss_web" defaultMessage="OK" />
          </Button>
        </Flex>
      </Flex>
    )
  }

  return (
    <Flex flexDirection="column" alignItems="center">
      <Flex
        flexDirection="column"
        alignItems="center"
        maxWidth={500}
        sx={{
          width: "100%",
        }}
        px={2}
        py={3}
      >
        <Heading textAlign="center" color="primary" mt={3}>
          <FormattedMessage id="iap.cta.header" defaultMessage="Unlock the full game" />
        </Heading>
        <Box
          mt={2}
          mb={3}
          mx={2}
          sx={{
            textAlign: "center",
          }}
        >
          <FormattedMessage
            id="iap.cta.description"
            defaultMessage="If you want to have the full REThink experience, unlock the full game by registering to a clinical study at app.rethinkplatform.ro/#/home!"
          />
        </Box>
        {config.environment === "game" ? (
          <>
            <Button m={2} onClick={onBuy}>
              <FormattedMessage id="iap.cta.purchase" defaultMessage="Unlock full game" />
            </Button>
            <Button m={2} onClick={onDismiss} variant="outline">
              <FormattedMessage id="iap.cta.dismiss" defaultMessage="Maybe later" />
            </Button>
          </>
        ) : (
          <>
            <Link m={1} target="_blank" href="https://apps.apple.com/us/app/rethink-game/id1544087907">
              <img height={40} alt="Download on the App Store" src={appStoreBadge} />
            </Link>
            <Link m={1} target="_blank" href="https://play.google.com/store/apps/details?id=org.rethinkplatform.rethink">
              <img height={40} alt="Download on Google Play" src={googlePlayBadge} />
            </Link>
            <Button m={2} onClick={onDismiss} variant="outline">
              <FormattedMessage id="iap.cta.dismiss_web" defaultMessage="OK" />
            </Button>
          </>
        )}
      </Flex>
    </Flex>
  );
}

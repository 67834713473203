import React, { ReactNode } from "react";
import { IntlShape, useIntl } from "react-intl";
import { Box, BoxProps } from "rebass";
import { APIError } from "../util/endpoints";

// This exists soley for intl:extract to pick up. Should be tree shaken when built
export function apiErrors(intl: IntlShape) {
  intl.formatMessage({ id: "error.password_too_short", defaultMessage: "Password too short" });
  intl.formatMessage({ id: "error.invalid_username", defaultMessage: "Invalid username" });
  intl.formatMessage({ id: "error.duplicate_username", defaultMessage: "This username is already taken by another account" });
  intl.formatMessage({ id: "error.invalid_email", defaultMessage: "Invalid email" });
  intl.formatMessage({ id: "error.duplicate_email", defaultMessage: "This e-mail is already in use by another account" });
  intl.formatMessage({ id: "error.invalid_reset_password_token", defaultMessage: "Invalid or already-used reset password link. Did you already reset your password using this link?" });
  intl.formatMessage({ id: "error.unknown_level", defaultMessage: "Unknown level" });
  intl.formatMessage({ id: "error.iap_validation_error", defaultMessage: "Cannot validate your purchase" });
  intl.formatMessage({ id: "error.friend_exists", defaultMessage: "Already friends" });
  intl.formatMessage({ id: "error.friend_request_doesnt_exist", defaultMessage: "Friend request doesn't exist" });
  intl.formatMessage({ id: "error.friend_request_self", defaultMessage: "Cannot send a friend request to yourself" });
  intl.formatMessage({ id: "error.supervisor_exists", defaultMessage: "User already added as a supervisor" });
  intl.formatMessage({ id: "error.user_not_found", defaultMessage: "User not found or not enough permissions" });
  intl.formatMessage({ id: "error.unauthorized", defaultMessage: "Unauthorized. Please log out and log in again" });
  intl.formatMessage({ id: "error.forbidden", defaultMessage: "You don't have permission to do that" });
  intl.formatMessage({ id: "error.rate_limit", defaultMessage: "Too many requests, please try again later" });
}

export function ErrorBox({ children, ...props }: Omit<BoxProps, "children"> & { children?: ReactNode | Error | null | undefined }) {
  const intl = useIntl();

  if (!children) { return null; }

  let errorMessage = children;
  if (children instanceof APIError) {
    errorMessage = intl.formatMessage({
      id: `error.${children.errorId}`,
      defaultMessage: children.message || children.toString(),
    });
  } else if (children instanceof Error) {
    errorMessage = children.toString();
  }

  return (
    <Box p={2} sx={{ borderRadius: "default", ...props.sx }} bg="error" color="textLight" {...props}>
      {errorMessage}
    </Box>
  );
}

import { parse } from "tiny-querystring";

export type AppConfig = {
  environment: "web" | "game" | "admin";
};

const config: AppConfig = {
  environment: (process.env.REACT_APP_ENVIRONMENT as any) || "admin",
};

Object.assign(config, (window as any).__AppConfig_config);

try {
  Object.assign(config, parse((window.location.hash || "").replace(/^#/, '')))
} catch (ex) {
  console.warn(ex);
}

export default config;

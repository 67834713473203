import React from "react";
import { Flex } from "rebass";
import { ScoresPage } from "../ScoresPage";
import { MultiselectNotSupported, SidebarTabProps } from "./SidebarUtils";

export function ScoresSidebarTab({ authApi, authUser, selectedUsers }: SidebarTabProps) {
  if (selectedUsers.length > 1) {
    return <MultiselectNotSupported />;
  }

  return (
    <Flex flexDirection="column" m={2} alignItems="stretch">
      <ScoresPage key={selectedUsers[0]} userId={selectedUsers[0]} showBreakdown showFriends={false} />
    </Flex>
  );
}
